import Vue from 'vue';
import App from './App.vue';
import store from "./store";
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { defineCustomElements } from '@carto/airship-components/dist/loader';
import VueMeta from 'vue-meta';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// import carto style assets
import '@carto/airship-style/dist/airship.css';

//console.log(`app version ${process.env.VUE_APP_VERSION}`);

// tell vue to ignore elements from carto airship
Vue.config.ignoredElements = [/as-\W+/];
Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.config.debug = false;
Vue.config.silent = true;
Vue.config.production = true;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);

new Vue({
  router: router,
  store: store,
  render: h => h(App),
}).$mount('#app');

// adding airship components to vue instnace
defineCustomElements(window);