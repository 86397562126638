import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { stringFixer } from './carto';
import { sez_table } from './static';
//import { stringFixer } from './carto';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [new VuexPersistence().plugin],
  state: {
    isMobile: null,
    intendedTo: null, // What is this?
    passwordAuth: false,
    // Is the cartodb id of an SEZ record, there will only be one in use to load the info for the SEZ page.
    sezId: null,
    /**
     * This will store data that needs to be served on the SEZ info page
     */
    sezPageData: {
      status: null,
      airportgps: null,
      airportlat: null,
      airportlong: null,
      capital_city: null,
      capital_city_distance_km: null,
      capitalgps: null,
      capitallat: null,
      capitallong: null,
      cartodb_id: null,
      city: null,
      citygps: null,
      citylat: null,
      citylong: null,
      country: null,
      countrycode: null,
      id: null,
      latitude: null,
      longitude: null,
      management_company: null,
      management_type: null,
      nearest_airport: null,
      nearest_airport_distance_km: null,
      nearest_port: null,
      nearest_port_distance_km: null,
      note: null,
      populous_city: null,
      populous_city_distance_km: null,
      portgps: null,
      portlat: null,
      portlong: null,
      region: null,
      rowid: null,
      sez_framework: null,
      //sezlocationgps: null,
      size_hectares: null,
      the_geom: null,
      the_geom_webmercator: null,
      url: null,
      zone_name: null,
      zone_type: null
    },
    /**
     * The following parameters allow controls to work on the advanced search page
     */
    portActive: null,
    portDistance: {
      min: null,
      max: null
    },
    airportActive: null,
    airportDistance: {
      min: null, 
      max: null
    },
    capitalActive: null,
    capitalDistance: {
      min: null,
      max: null
    },
    populousActive: null,
    populousDistance: {
      min: null,
      max: null
    },
    /**
     * This will serve info that is needed by the advanced search logic
     */
    advancedSearch: {
      region: null,
      zone_type: null,
      management_type: null,
      management_company: null,
      distance: null,
      activity: null,
      size_hectares: [null, null],
      size_null: null,
      keyWord: null,
      zone_specialization: null
    },
    uniformSEZsize: false,
    colorLegendType: null,
    color_selections: [],
    show_selected: [],
    filter_selections: [], // This is an array of zone types, Eg: strings.
    countrySelectedOnMap: null,
    quickSearchString: null
  },
  computed: {
    
  },
  actions: {
    /** UNUSED */
    async saveSezPageId({ commit }, sezId) {
      commit('setSezPageDataId', sezId);
    },
    async fetchSezData({ commit }, payload) {
      let {zoneName, country} = payload;
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }
      const query = `SELECT * FROM ${sez_table} WHERE zone_name = '${zoneName}' AND country = '${country}'`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      //console.log('fetSezData()', query);
      const response = await fetch(
        url,
        requestOptions
      )
      const text = await response.json()
      //console.log('fetSezData()', text);
      const sezData = await text.rows[0]
      for (const [key, value] of Object.entries(sezData)) {
        sezData[key] = stringFixer(value);
      }
      commit('setSezPageData', { sezData: sezData })
      commit('setSezId', { sezId: sezData.id });
    },
    updateAdvancedSearchParameters({ commit }, params) {
      /**
       * Maybe do some checking here
       */
      commit('setAdvancedSearchParameters', params);
    },
    updateUniformSEZsize({ commit }, params) {
      /**
       * Maybe do some checking here
       */
      commit('setUniformSEZsize', params);
    },
    crossApplyLegendWithZoneFilter({ state }, isActivityLayer = false) {
      const stringArray = [];
      if (state.advancedSearch.keyWord) {
        let keyWordSearch = `(
          country ILIKE '%${state.advancedSearch.keyWord}%'
          OR country ILIKE '%${state.advancedSearch.keyWord}'
          OR zone_name ILIKE '%${state.advancedSearch.keyWord}%' 
          OR nearest_port ILIKE '%${state.advancedSearch.keyWord}%' 
          OR nearest_airport ILIKE '%${state.advancedSearch.keyWord}%' 
          OR populous_city ILIKE '%${state.advancedSearch.keyWord}%'
          OR management_company ILIKE '%${state.advancedSearch.keyWord}%')`;
        stringArray.push(keyWordSearch);
      }
      // We need to get sez's by the kind of zone that they are
      if (state.filter_selections.length > 0) {
        let zoneTypeArray = [];
        state.filter_selections.includes('sez') ? zoneTypeArray.push("zone_type = 'Specialized Zone'") : ""
        state.filter_selections.includes('epz') ? zoneTypeArray.push("zone_type = 'Export Processing Zone'") : ""
        state.filter_selections.includes('ftz') ? zoneTypeArray.push("zone_type = 'Free Trade Zone'") : ""
        state.filter_selections.includes('dz') ? zoneTypeArray.push("zone_type = 'Diversified Zone'") : ""
        state.filter_selections.includes('rrz') ? zoneTypeArray.push("zone_type = 'Economic Revitalization Project'") : ""
        state.filter_selections.includes('cc') ? zoneTypeArray.push("zone_type = 'Charter City'") : ""
        state.filter_selections.includes('ForeignTrade') ? zoneTypeArray.push("zone_type = 'Foreign Trade Zone'") : ""
        state.filter_selections.includes('OtherZone') ? zoneTypeArray.push("zone_type = 'Other Zone'") : ""
        let zoneString = '( ';
        let zoneConditionLength = zoneTypeArray.length - 1;
        for (let i  = 0; i < zoneTypeArray.length; i++) {
          zoneString += `${zoneTypeArray[i]} `;
          if (zoneConditionLength > 0) {
            zoneString += `OR `;
          }
          zoneConditionLength = zoneConditionLength - 1;
        }
        zoneString = zoneString + ')';
        //console.log('crossApplyLegendWithZoneFilter', 'zoneString', zoneString);
        stringArray.push(zoneString);
      }

      if (state.advancedSearch.region) {
        stringArray.push(`region = '${state.advancedSearch.region}'`);
      }

      if (state.advancedSearch.management_company) {
         stringArray.push(`LOWER(management_company) LIKE LOWER('%${state.advancedSearch.management_company}%')`);
      }
      // Do not engage this if this is being triggered from the activity_status draw layer
      if (state.advancedSearch.activity && !isActivityLayer) {
        stringArray.push(`status = '${state.advancedSearch.activity}'`);
      }

      if (state.advancedSearch.zone_specialization) {
        stringArray.push(`zone_specialization = '${state.advancedSearch.zone_specialization}'`);
      }

      if (state.advancedSearch.size_hectares[0] != null && state.advancedSearch.size_hectares[1] != null) {
        stringArray.push(`size_hectares >= ${state.advancedSearch.size_hectares[0]} AND size_hectares <= ${state.advancedSearch.size_hectares[1]}`)
      }

      if (state.advancedSearch.size_null) {
        stringArray.push(`size_hectares is null`);
      }
      ////////////////////////////////////////////////////////////
      if (state.portActive) {
        stringArray.push(`nearest_port_distance_km >= ${state.portDistance.min}  AND nearest_port_distance_km <= ${state.portDistance.max}`);
      }

      if (state.airportActive) { 
        stringArray.push(`nearest_airport_distance_km >= ${state.airportDistance.min} AND nearest_airport_distance_km <= ${state.airportDistance.max}`);
      }

      if (state.capitalActive) {
        stringArray.push(`capital_city_distance_km >= ${state.capitalDistance.min} AND capital_city_distance_km <= ${state.capitalDistance.max}`);
      }

      if (state.populousActive) {
        stringArray.push(`populous_city_distance_km >= ${state.populousDistance.min} AND populous_city_distance_km <= ${state.populousDistance.max}`);
      }
      /////////////////////////////////////////////////////////
      if (state.countrySelectedOnMap) {
        stringArray.push(`country = '${state.countrySelectedOnMap}'`);
      }

      let whereConditionString = '';
      let conditionLength = stringArray.length - 1
      for (let i = 0; i < stringArray.length; i++) {
        whereConditionString += `${stringArray[i]} `;
        if (conditionLength > 0) {
          whereConditionString += `AND `;
        }
        conditionLength = conditionLength - 1;
      }
      //console.log("store.crossApplyLegendWithZoneFilter()", whereConditionString);
      //console.log(stringArray);
      return whereConditionString;
    },
    /**
     * This is used by the functions the need to add aditional layers to the map.
     * For this reason, condtions are skiped for 'zone_type', 'management_type', and 'status'.
     * If they were included it would be possible for those layers queries to not display the appropriate selection for themselves.
     * @param {*} param0 
     * @returns 
     */
    composeAdvancedSearchWhereConditions( { state } ) {
      const stringArray = [];
      if (state.advancedSearch.keyWord) {
        let keyWordSearch = `(
          country ILIKE '%${state.advancedSearch.keyWord}%'
          OR country ILIKE '%${state.advancedSearch.keyWord}'
          OR zone_name ILIKE '%${state.advancedSearch.keyWord}%' 
          OR nearest_port ILIKE '%${state.advancedSearch.keyWord}%' 
          OR nearest_airport ILIKE '%${state.advancedSearch.keyWord}%' 
          OR populous_city ILIKE '%${state.advancedSearch.keyWord}%'
          OR management_company ILIKE '%${state.advancedSearch.keyWord}%')`;
        stringArray.push(keyWordSearch);
      }

      if (state.advancedSearch.region) {
        stringArray.push(`region = '${state.advancedSearch.region}'`);
      }

      // This is not used
      if (state.advancedSearch.management_company) {
         stringArray.push(`LOWER(management_company) LIKE LOWER('%${state.advancedSearch.management_company}%')`);
      }

      if (state.advancedSearch.zone_specialization) {
        stringArray.push(`zone_specialization = '${state.advancedSearch.zone_specialization}'`);
      }

      if (state.advancedSearch.size_hectares[0] != null && state.advancedSearch.size_hectares[1] != null) {
        stringArray.push(`size_hectares >= ${state.advancedSearch.size_hectares[0]} AND size_hectares <= ${state.advancedSearch.size_hectares[1]}`)
      }

      if (state.advancedSearch.size_null) {
        stringArray.push(`size_hectares is null`);
      }
      ////////////////////////////////////////////////////////////
      if (state.portActive) {
        stringArray.push(`nearest_port_distance_km >= ${state.portDistance.min}  AND nearest_port_distance_km <= ${state.portDistance.max}`);
      }

      if (state.airportActive) { 
        stringArray.push(`nearest_airport_distance_km >= ${state.airportDistance.min} AND nearest_airport_distance_km <= ${state.airportDistance.max}`);
      }

      if (state.capitalActive) {
        stringArray.push(`capital_city_distance_km >= ${state.capitalDistance.min} AND capital_city_distance_km <= ${state.capitalDistance.max}`);
      }

      if (state.populousActive) {
        stringArray.push(`populous_city_distance_km >= ${state.populousDistance.min} AND populous_city_distance_km <= ${state.populousDistance.max}`);
      }
      /////////////////////////////////////////////////////////
      if (state.countrySelectedOnMap) {
        stringArray.push(`country = '${state.countrySelectedOnMap}'`);
      }

      let whereConditionString = '';
      let conditionLength = stringArray.length - 1
      for (let i = 0; i < stringArray.length; i++) {
        whereConditionString += `${stringArray[i]} `;
        if (conditionLength > 0) {
          whereConditionString += `AND `;
        }
        conditionLength = conditionLength - 1;
      }
     //console.log("Store.composeAdvancedSearchWhereConditions()", whereConditionString);
      //console.log(stringArray);
      return whereConditionString;
    },
    composeSQL({ state }) {
      const queryStringPrepend = `SELECT * from ${sez_table} WHERE `;
      const stringArray = [];

      if (state.advancedSearch.keyWord) {
        let keyWordSearch = `
          country ILIKE '%${state.advancedSearch.keyWord}%'
          OR country ILIKE '%${state.advancedSearch.keyWord}'
          OR zone_name ILIKE '%${state.advancedSearch.keyWord}%' 
          OR nearest_port ILIKE '%${state.advancedSearch.keyWord}%' 
          OR nearest_airport ILIKE '%${state.advancedSearch.keyWord}%' 
          OR populous_city ILIKE '%${state.advancedSearch.keyWord}%'
          OR management_company ILIKE '%${state.advancedSearch.keyWord}%'`;
        stringArray.push(keyWordSearch);
      }

      if (state.advancedSearch.region) {
        stringArray.push(`region = '${state.advancedSearch.region}'`);
      }

      if (state.advancedSearch.zone_type) {
        stringArray.push(`zone_type = '${state.advancedSearch.zone_type}'`);
      }

      if (state.advancedSearch.zone_specialization) {
        stringArray.push(`zone_specialization = '${state.advancedSearch.zone_specialization}'`);
      }

      if (state.advancedSearch.management_type) {
        stringArray.push(`management_type = '${state.advancedSearch.management_type}'`);
      }

      // This is not used
      if (state.advancedSearch.management_company) {
        stringArray.push(`LOWER(management_company) LIKE LOWER('%${state.advancedSearch.management_company}%')`);
      }

      if (state.advancedSearch.activity) {
        stringArray.push(`status = '${state.advancedSearch.activity}'`);
      }

      if (state.advancedSearch.size_hectares[0] != null && state.advancedSearch.size_hectares[1] != null) {
        stringArray.push(`size_hectares >= ${state.advancedSearch.size_hectares[0]} AND size_hectares <= ${state.advancedSearch.size_hectares[1]}`)
      }

      if (state.advancedSearch.size_null) {
        stringArray.push(`size_hectares is null`);
      }
      ////////////////////////////////////////////////////////
      if (state.portActive) {
        stringArray.push(`nearest_port_distance_km >= ${state.portDistance.min}  AND nearest_port_distance_km <= ${state.portDistance.max}`);
      }

      if (state.airportActive) { 
        stringArray.push(`nearest_airport_distance_km >= ${state.airportDistance.min} AND nearest_airport_distance_km <= ${state.airportDistance.max}`);
      }

      if (state.capitalActive) {
        stringArray.push(`capital_city_distance_km >= ${state.capitalDistance.min} AND capital_city_distance_km <= ${state.capitalDistance.max}`);
      }

      if (state.populousActive) {
        stringArray.push(`populous_city_distance_km >= ${state.populousDistance.min} AND populous_city_distance_km <= ${state.populousDistance.max}`);
      }
      ////////////////////////////////////////////////////////
      if (state.countrySelectedOnMap) {
        stringArray.push(`country = '${state.countrySelectedOnMap}'`);
      }

      let whereConditionString = '';
      let conditionLength = stringArray.length - 1;
      for (let i = 0; i < stringArray.length; i++) {
        whereConditionString += `${stringArray[i]} `;
        if (conditionLength > 0) {
          whereConditionString += `AND `;
        }
        conditionLength = conditionLength - 1;
      }

      const queryString = `${queryStringPrepend} ${whereConditionString}`;
      //console.log('Store.composeSQL() query:', queryString);
      return queryString;
    },
    /**
     * Uses a request to the Carto SQL API endpoints
     * Uses response to fill drop down list for zone type drop down
     */
    async queryPorts(keyword) {
      if (!keyword) { return; } // if keyword is empty do nothing
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      const query = `SELECT distinct nearest_port FROM ${sez_table} WHERE nearest_port SIMILAR TO '_*${keyword}_*'`;
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}`;
      const response = await fetch(
        url,
        requestOptions
      );
      const text = await response.json();
      let result = text.rows.map(i => i.nearest_port);
      return result;
    },
    async queryAirports(keyword) {
      if (!keyword) { return; } // if keyword is empty do nothing
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      const query = `SELECT distinct nearest_airport FROM ${sez_table} WHERE nearest_airport SIMILAR TO '_*${keyword}_*'`;
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}`;
      const response = await fetch(
        url,
        requestOptions
      );
      const text = await response.json();
      let result = text.rows.map(i => i.nearest_airport);
      return result;
    },
    async queryCapitals(keyword) {
      if (!keyword) { return; } // if keyword is empty do nothing
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      const query = `SELECT distinct capital_city FROM ${sez_table} WHERE capital_city SIMILAR TO '_*${keyword}_*'`;
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}`;
      const response = await fetch(
        url,
        requestOptions
      );
      const text = await response.json();
      let result = text.rows.map(i => i.capital_city);
      return result;
    },
    async queryPopulousCities(keyword) {
      if (!keyword) { return; } // if keyword is empty do nothing
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      const query = `SELECT distinct populous_city FROM ${sez_table} WHERE populous_city SIMILAR TO '_*${keyword}_*'`;
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}`;
      const response = await fetch(
        url,
        requestOptions
      );
      const text = await response.json();
      let result = text.rows.map(i => i.populous_city);
      return result;
    },
    updateInfrastructureProximityParameters({ commit }, params) {
      commit('setInfrastructureProximityParameters', params);
    },
    updatePasswordAuth({ commit }) {
      commit('setPasswordAuth');
    },
    updateIntendedTo({ commit }, path) {
      commit('setIntendedPath', path);
    },
    updateIsMobile({commit}, params) {
      commit('setIsMobile', params);
    },
    updateKeywordSearchString({commit}, params) {
      commit('setKeyWordSearchString', params);
    },
    clearKeywordSearchString({commit}) {
      commit('unsetKeyWordSearchString');
    },
    updateColorFilterSelection({commit}, param) {
      if(param) {
        commit('setColorFilter', param);
      } else {
        commit('unsetColorFilter');
      }
    },
    updatePortDistance({commit}, value) {
      commit('setPortDistance', value);
    },
    updateAirPortDistance({commit}, value) {
      commit('setAirportDistance', value);
    },
    updateCapitalDistance({commit}, value) {
      commit('setCapitalDistance', value);
    },
    updatePopulousDistance({commit}, value) {
      commit('setPopulousDistance', value);
    }
  },
  /*
    Getters defined in store.js
  */
  getters: {
    passwordAuthorized: state => { return state.passwordAuth; },
    getIntendPath: state => { return state.intendedTo; },
    getSezPageData: state => { return state.sezPageData; },
    getSezId: state => { return state.sezId; },
    getAdvancedSearchParameters: state => { return state.advancedSearch; },
    getUniformSEZsize: state => { return state.uniformSEZsize; },
    //getNearbyPort: state => { return state.advancedSearch.nearby.port; },
    //getNearbyAirport: state => { return state.advancedSearch.nearby.airport; },
    //getNearbyCapital: state => { return state.advancedSearch.nearby.capitalCity; },
    //getNearbyPopulous: state => { return state.advancedSearch.nearby.populousCity; },
    /**
     * Return true if any of the Advanced Search parameters are true-ish
     * @param {Vuex} state 
     * @returns 
     */
    isAdvancedSearchNeeded: state => { 
      if (
        state.advancedSearch.region ||
        state.advancedSearch.zone_type ||
        state.advancedSearch.management_type ||
        state.advancedSearch.management_company ||
        state.advancedSearch.distance ||
        state.advancedSearch.activity ||
        state.advancedSearch.size_hectares[0] || // we are only checking if the first array element is not null here
        state.advancedSearch.size_null ||
        state.portActive ||
        state.airportActive ||
        state.capitalActive ||
        state.populousActive ||
        state.advancedSearch.keyWord ||
        state.advancedSearch.zone_specialization ||
        state.countrySelectedOnMap
      ) { return true; } else {  return false; }
    },
    /**
     * Return true if any filtering state paramteters are true-ish
     * @param {Vuex} state 
     * @returns 
     */
    isMapFilteringNeeded: (state, getters) => {
      if (
        state.colorLegendType || getters.isZoneTypeFilteringNeeded
      ) { return true; } else { return false; }
    },
    /**
     * We need to know if zone type filtering has been requested
     * @param {Vuex} state 
     * @returns 
     */
    isZoneTypeFilteringNeeded: state => {
      if (state.filter_selections.length > 0) {
        return true;
      } else { return false; }
    },
    isLegendNeeded: (state, getters) => {
      if(
        state.colorLegendType || getters.isMapFilteringNeeded
      ) {return true;} else {return false;}
    }
  },
  mutations: {
    setIsMobile(state, mobile) {
      state.isMobile = mobile;
    },
    setSezPageDataId(state, sezId) {
      state.sezId = sezId;
    },
    setPasswordAuth(state) {
      state.passwordAuth = true;
    },
    setIntendedPath(state, path) {
      state.intendedTo = path;
    },
    setSezPageData(state, payload) {
      //console.log('setSezPageData', payload);
      state.sezPageData = payload.sezData;
    },
    setSezId(state, payload) {
      state.sezId = payload.sezId;
    },
    setAdvancedSearchParameters(state, payload) {
      if (payload.region) state.advancedSearch.region = payload.region;
      if (payload.zone_type) state.advancedSearch.zone_type = payload.zone_type;
      if (payload.management_type) state.advancedSearch.management_type = payload.management_type;
      if (payload.management_company) state.advancedSearch.management_company = payload.management_company;
      if (payload.distance) state.advancedSearch.distance = payload.distance;
      if (payload.activity) state.advancedSearch.activity = payload.activity;
      if (payload.size_hectares) { 
        state.advancedSearch.size_hectares = payload.size_hectares;
        //console.log('setAdvancedSearchParameters', state.advancedSearch);
      }
      if (payload.size_null) {
        state.advancedSearch.size_null = payload.size_null;
       //console.log('setAdvancedSearchParameters', state.advancedSearch.size_null);
      }
      if (payload.populousCity) state.advancedSearch.nearby.populousCity.active = payload.populousCity;
      if (payload.capitalCity) state.advancedSearch.nearby.capitalCity.active = payload.capitalCity;
      if (payload.airport) state.advancedSearch.nearby.airport.active = payload.airport;
      if (payload.port) state.advancedSearch.nearby.port.active = payload.port;
      if (payload.search_key_word) state.advancedSearch.keyWord = payload.search_key_word;
      if (payload.zone_specialization) state.advancedSearch.zone_specialization = payload.zone_specialization;
      //console.log('setAdvancedSearchParameters', state.advancedSearch);
    },
    /**
     * Nullify the advanced search paramter specified
     * @param {*} state 
     * @param {String} payload, the advanced search key to nullify 
     */
    nullifyAdvancedSearchParameter(state, payload) {
      if(payload === 'size_hectares') {
        state.advancedSearch[payload] = [null, null];
      } else if(payload === 'size_null') {
        state.advancedSearch[payload] = false;
      } else {
        state.advancedSearch[payload] = null;
      }
    },
    /* 
    
    */
    unsetAdvancedSearchParameters(state) {
      state.advancedSearch = {
        region: null,
        zone_type: null,
        management_type: null,
        management_company: null,
        distance: null,
        activity: null,
        size_hectares: [null, null],
        size_null: null,
        keyWord: null,
        zone_specialization: null,
        nearby: {
          port: {
            active: null,
            distance: [null, null],
          },
          airport: {
            active: null,
            distance: [null, null],
          },
          capitalCity: {
            active: null,
            distance: [null, null],
          },
          populousCity: {
            active: null,
            distance: [null, null],
          },
        },
      };
    },
    setUniformSEZsize(state, payload) {
      state.uniformSEZsize = payload;
    },
    setInfrastructureProximityParameters(state, payload) {
      if (payload.ctl === 'capital') {
        state.advancedSearch.nearby.capitalCity.distance = payload.value;
        state.advancedSearch.nearby.capitalCity.active = true;
      }
      if (payload.ctl === 'populous') {
        state.advancedSearch.nearby.populousCity.distance = payload.value;
        state.advancedSearch.nearby.populousCity.active = true;
      }
      if (payload.ctl === 'airport') { 
        state.advancedSearch.nearby.airport.distance = payload.value;
        state.advancedSearch.nearby.airport.active = true;
      }
      if (payload.ctl === 'port') { 
        state.advancedSearch.nearby.port.distance = payload.value;
        state.advancedSearch.nearby.port.active = true;
      }
    },
    /**
     * Not yet used
     * @param {*} state 
     * @param {String} payload, the infrstrucutre key to nullify values for 
     */
    nullifyInfrastructureSearchParameters(state, payload) {
      state.advancedSearch.nearby[payload].active = null;
      state.advancedSearch.nearby[payload].distance = [null, null];
    },
    unsetColorFilter(state) {
      state.colorLegendType = null;
    },
    setColorFilter(state, payload) {
      state.colorLegendType = payload;
    },
    unsetShowFilter(state) {
      state.show_selected = [];
    },
    setShowFilter(state, payload) {
      state.show_selected = payload;
    },
    setFilterSelections(state, payload) {
     //console.log('setFilterSelections', payload);
      state.filter_selections = payload;
    },
    unsetFilterSelections(state) {
      state.filter_selections = [];
    },
    unsetQuickSearch(state) {
      state.quickSearchString = null;
    },
    setQuickSearchString(state, payload) {
      state.quickSearchString = payload;
    },
    setKeyWordSearchString(state, payload) {
      state.advancedSearch.keyWord = payload;
    },
    unsetKeyWordSearchString(state) {
      state.advancedSearch.keyWord = null;
    },
    setSizeHectares(state, payload) {
      state.advancedSearch.size_hectares = payload;
    },
    setSizeNulls(state, payload) {
      state.advancedSearch.size_null = payload;
    },
    /**
     * 
     * @param {*} state 
     * @param {object} payload, has prperties 'min' and 'max' 
     */
    setPortDistance(state, payload) {
      state.portActive = true;
      state.portDistance = payload;
    },
    unsetPortDistance(state) {
      state.portActive = null;
      state.portDistance = {min: null, max: null};
    },
    setAirportDistance(state, payload) {
      state.airportActive = true;
      state.airportDistance = payload;
    },
    setCapitalDistance(state, payload) {
      state.capitalActive = true;
      state.capitalDistance = payload;
    },
    unsetCapitalDistance(state) {
      state.capitalActive = null;
      state.capitalDistance = {min: null, max: null};
    },
    setPopulousDistance(state, payload) {
      state.populousActive = true;
      state.populousDistance = payload;
    },
    unsetAirportDistance(state) {
      state.airportActive = null;
      state.airportDistance = {min: null, max: null};
    },
    unsetPopulousDistance(state) {
      state.populousActive = null;
      state.populousDistance = {min: null, max: null};
    }
  },
  
});