<template>
  <div id="splash">
    <div class="container">
      <b-row align-v="center" align-h="center">
        <b-col>
          <div class="page-intro">
            <div>
              <h1>Open Zone Map</h1>
              <p>BETA</p>
            </div>
            <h2>Mapping the World's Special Economic Zones</h2>
          </div>
          <div class="quick-search">
            <quick-search/>
          </div>
          <div class="page-nav">
            <b-button to="/Advanced">ADVANCED SEARCH</b-button>
            <b-button to="/map">SEE THE MAP <BIconArrowRight id="right-arrow" /></b-button>
          </div>
          <div class="page-bottom"></div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import QuickSearch from '../components/QuickSearch.vue';
import { BIconArrowRight } from 'bootstrap-vue';
export default {
  components: {
    QuickSearch,
    BIconArrowRight
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
//OVERRIDE 
#app::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
#app {
  overflow-y: scroll; /* Add the ability to scroll */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#splash {
  position: relative;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
  }
}
.page-nav {
  margin-top: 3rem;
}
.btn-secondary {
  position: relative;
  background-color: rgba(0,0,0,0) !important;
  border-color: rgb(167, 135, 109) !important;
  border-radius: 0px !important;
  color: #fff !important;
  margin-top: 1rem;
  width: 15rem;
  svg {
    position: absolute;
    right: 1rem;
    top: 22.5%;
    //top: 100%;
  }
}
.btn-secondary:hover {
  background-color: #fff !important;
  color: rgb(22, 35, 52) !important;
}
.page-intro {
  padding-bottom: 1.5rem;
  div {
    position: relative;
    margin-bottom: 1.0rem;
    h1, p {
      display: inline;
    }
    h1 {
      font-size: 3rem !important;
    }
    p {
      padding-left: 0.5rem;
      color: rgb(167, 135, 109);
      font-weight: 600;
      font-family: 'Roboto', sans-serif;
      margin-bottom: unset;
    }
  }
  h2 {
    color: rgb(167, 135, 109);
    font-size: 1.2rem !important;
  }
}
#right-arrow {
  color: rgb(167, 135, 109);
}
.page-bottom {
  height: 10rem;
}
</style>