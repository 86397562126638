<template>
  <div class="mobile-tags-container">
    <label>ACTIVE TAGS</label>
    <b-row v-if="advancedSearch.region || advancedSearch.zone_type">
      <b-button 
        v-if="advancedSearch.zone_type"
        class="custom-mobile-tag"
        @click="removeCriteria('zone_type')"
      >
        {{advancedSearch.zone_type}}
        <BIconX />
      </b-button>
      <b-button 
        v-if="advancedSearch.region"
        class="custom-tag"
        @click="removeCriteria('region')"
      >
        {{advancedSearch.region}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row 
      align-h="start"
      v-if="advancedSearch.zone_specialization || advancedSearch.management_type"
    >
      <b-button 
        class="custom-tag"
        @click="removeCriteria('management_type')"
      >
        {{advancedSearch.management_type}}
        <BIconX />
      </b-button>
      <b-button 
        v-if="advancedSearch.zone_specialization"
        class="custom-tag"
        @click="removeCriteria('zone_specialization')"
      >
        {{advancedSearch.zone_specialization}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row
      v-if="advancedSearch.activity"
    >
      <b-button 
        v-if="advancedSearch.activity"
        class="custom-tag"
        @click="removeCriteria('activity')"
      >
        {{advancedSearch.activity}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row
      v-if="portActive || airportActive"
    >
      <b-button 
        v-if="portActive"
        class="custom-tag"
        @click="removeCriteria('port')"  
      >
        Port {{ portDistance.min }}km - {{ portDistance.max }}km
        <BIconX />
      </b-button>
      <b-button 
        v-if="airportActive"
        class="custom-tag"
        @click="removeCriteria('airport')"  
      >
        Airport {{ airportDistance.min }}km - {{ airportDistance.max }}km
        <BIconX />
      </b-button>
    </b-row>
    <b-row
      v-if="airportActive || capitalActive || populousActive"
    >
      <b-button 
        v-if="capitalActive"
        class="custom-tag"
        @click="removeCriteria('capitalCity')"  
      >
        Capital {{ capitalDistance.min }}km - {{ capitalDistance.max }}km
        <BIconX />
      </b-button>
      <b-button 
        v-if="populousActive"
        class="custom-tag"
        @click="removeCriteria('populousCity')"  
      >
        Metro {{ populousDistance.min }}km - {{ populousDistance.max }}km
        <BIconX />
      </b-button>
    </b-row>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { BIconX } from 'bootstrap-vue'
export default {
  computed: {
    ...mapState([
      'advancedSearch',
      'portActive',
      'portDistance',
      'airportActive',
      'airportDistance',
      'capitalActive',
      'capitalDistance',
      'populousActive',
      'populousDistance'
    ]),
  },
  data() {
    return {

    }
  },
  components: {
    BIconX
  },
  methods: {
    removeCriteria(e) {
      if(e == 'port') {
        this.$store.commit('unsetPortDistance');
      } else if (e == 'airport'){
        this.$store.commit('unsetAirportDistance');
      } else if (e == 'capitalCity') {
        this.$store.commit('unsetCapitalDistance');
      } else if (e == 'populousCity') {
        this.$store.commit('unsetPopulousDistance');
      } else {
        this.$store.commit('nullifyAdvancedSearchParameter', e);
      }
      this.$emit("update-filters", 'removed_tag');
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-tags-container {
  div.row {
    margin-left: 0.25rem;
    padding-top: 0.25rem;
    button {
      color: rgb(79, 103, 130) !important;
      border-color: rgb(79, 103, 130) !important;
      background-color: rgba(0, 0, 0, 0) !important;
      border-radius: unset !important;
      margin-left: 0.125rem;
      margin-right: 0.125rem;
      font-size: 0.8rem;
    }
  }
}
.mobile-tags-container > label {
  margin-left: 0.5rem;
}
</style>