<template>
  <div id="app" v-bind:style="{'background-image': 'url(' + dynamicBackground()+ ')'}">
    <div v-if="getConditionallyRenderedNavBar() == 'Home' || getConditionallyRenderedNavBar() == 'Password'">
      <NavBarMin/>
    </div>
    <div v-else-if="getConditionallyRenderedNavBar() == 'Menu'">
      
    </div>
    <div v-else>
      <NavBar/>
    </div>
    <router-view/>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import NavBarMin from './components/NavBarMin.vue';
import backgroundImage from './assets/First-Page.svg';
import { mapState } from 'vuex';
export default {
  name: 'App',
  metaInfo() {
    return { 
      title: "Open Zone Map Beta",
      meta: [
        { name: 'description', content:  "Mapping the World's Special Economic Zones"},
        { property: 'og:title', content: "Open Zone Map Beta"},
        { property: 'og:site_name', content: 'openzonemap' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' } 
      ]
    }
  },
  components: {
    NavBar,
    NavBarMin
  },
  data() {
    return {
      backgroundImage
    }
  },
  computed: {
    ...mapState([
      'isMobile'
    ])
  },
  methods: {
    getConditionallyRenderedNavBar() {
      return this.$route.name;
    },
    dynamicBackground() {
      if(this.$route.name === 'Home' || this.$route.name === 'Password') {
        return this.backgroundImage;
      } else {
        return null;
      }
    },
    /***
     * TODO: This needs to be improved.
     * Should create a minimum screen width and height to identify mobile devices.
     */
    setIsMobile() {
      if(window.innerWidth / window.innerHeight < 1) {
          this.$store.dispatch('updateIsMobile', true);
        } else {
          this.$store.dispatch('updateIsMobile', false );
        }
    },
    /**
     * DOES NOT WORK
     */
    orientationLock() {
      let orientation = screen.orientation;
      let de = document.documentElement;
      if (de.requestFullscreen) { de.requestFullscreen();
      } else if (de.mozRequestFullScreen) { de.mozRequestFullScreen(); 
      } else if (de.webkitRequestFullscreen) { de.webkitRequestFullscreen(); 
      } else if (de.msRequestFullscreen) { de.msRequestFullscreen(); 
      } 
      screen.orientation.lock(orientation.type)
     //console.log('orientationLock', orientation);
    }
  },
  async mounted() {
    //console.log('App: mounted::', this.getConditionallyRenderedNavBar())
    //window.addEventListener("resize", this.setIsMobile);
    //this.setIsMobile();
   //console.log('App.mounted fired')
    //this.orientationLock();
  },
  created() {
    // let iHeight = window.innerHeight;
    // let iWidth = window.innerWidth;
    ////console.log('App.created()', iHeight, iWidth);
    this.setIsMobile();
  },
  destroyed() {
    //window.removeEventListener("resize", this.setIsMobile);
  }
}
</script>
<style>

@import url('https://libs.cartocdn.com/mapbox-gl/v1.13.0/mapbox-gl.css');
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.css');
@import url('https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css' rel='stylesheet');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  /* margin-top: 60px; */
  background-color: rgb(22, 35, 52);
  /* background-color: rgb(255, 255, 255); */
  height: 100vh;
}
@media (max-height: 425px) {
  #app {
    height: 200vh;
  }
}

.tag {
  border-bottom: 2px solid #0056b3;
  padding-bottom: 2px;
  letter-spacing: 1px;
  margin-left: 5px;
  color: #000 !important;
}

.input-clear {
  position:absolute;
  right: 35%;
  top: 35%;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  cursor: pointer;
}

h1 { 
  font-family: 'EBGaramond-VariableFont_wght' !important;
  font-size: 40px !important;
  color: #fff;
}

h2 { 
  font-family: 'EBGaramond-Italic-VariableFont_wght' !important;
  font-size: 36px !important;
}

h3 { 
  font-family: 'EBGaramond-VariableFont_wght' !important;
  font-size: 32px !important;
}

h4 {
  font-family: 'EBGaramond-VariableFont_wght' !important;
  font-size: 32px !important;
}

.btn {
    margin-left: 1rem;
    margin-right: 1rem;
  }

/* @media screen and (min-aspect-ratio: 13/9) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
} */

</style>
