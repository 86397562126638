<template>
  <b-navbar v-bind:style="{ 'background-color': backgroundStyle, 'display': display}">
    <!-- <div 
      v-if="isMobile"
      class="quick-search nav-item"
    >
      <BIconSearch class="rounded-circle border p-2"/>
    </div> -->
    <div 
      v-if="!isMobile"
      class="quick-search nav-item"
    >
      <quick-search/>
    </div>
    <div class="middle-item nav-item">
      <b-navbar-brand href="/">
        <div v-if="isMobile">
          <h3 
            v-bind:style="{ 'color': colorStyle }"
          >
            Open Zone Map
          </h3>
          <p>BETA</p>
        </div>
        <div v-else>
          <h1
            v-bind:style="{ 'color': colorStyle }"
          >
            Open Zone Map
          </h1>
          <p>BETA</p>
        </div>
      </b-navbar-brand>
    </div>
    <div 
      v-if="getPath() === 'Advanced Search' & !isMobile"
      class="end-item nav-item" 
    >
      <b-navbar-brand href="/map">
        <BIconMap class="rounded-circle border p-2"/>
      </b-navbar-brand>
    </div>
    <div 
      v-else-if="getPath() === 'SEZ'"
      class="end-item nav-item" 
    >
      <b-navbar-brand>
        <BIconXCircle class="exit-sez" @click="navigateToMap()" />
      </b-navbar-brand>
    </div>
    <div
      v-else
      class="end-item nav-item"
    >
      <b-navbar-brand v-on:click = "showMenu">
        <b-icon-justify class="rounded-circle border p-2"/>
      </b-navbar-brand>
    </div>
    <Menu v-if="getPath() != 'Map' & isMobile"></Menu>
  </b-navbar>
</template>

<script>

import QuickSearch from '../components/QuickSearch.vue';
import Menu from '../components/Menu.vue'
import { mapState, mapGetters } from 'vuex';
import {
  BIconJustify,
  BIconMap,
  //BIconSearch
} from 'bootstrap-vue';
export default {
  components: {
    QuickSearch,
    BIconJustify,
    Menu,
    BIconMap,
    //BIconSearch
  },
  data() {
    return {
      backgroundStyle:'rgb(22, 35, 52)',
      colorStyle: 'rgb(225, 225, 225)',
      display: 'flex'
    }
  },
  computed: {
    ...mapState([
      'isMobile'
    ]),
    ...mapGetters([
      'isAdvancedSearchNeeded'
    ])
  },
  methods: {
    showMenu() {
      var element = document.querySelector("#menu");
      element.style.visibility = "visible";
      var op = 0.1;  // initial opacity
      element.style.display = 'block';
      var timer = setInterval(
        function () {
          if (op >= 1){
            clearInterval(timer);
          }
          element.style.opacity = op;
          element.style.filter = 'alpha(opacity=' + op * 100 + ")";
          op += op * 0.1;
          //console.log('showMenu Interval Active', op);
        }, 
        10
      );
    },
    getPath() {
      return this.$route.name;
    },
    conditionalBackground() {
     //console.log('conditionalBackground', this.getPath())
      if(this.getPath() == "Advanced Search") {
        this.backgroundStyle = 'rgb(255, 255, 255)';
        this.colorStyle = 'rgb(0, 0, 0)';
        this.display = 'flex';
      } else if (this.getPath() == "Map") {
        // TODO: check this works well for devices that are already in a mobile view, However this breaks if toggleing screen size happens
        if(this.isMobile) {
          this.backgroundStyle = 'rgba(22, 35, 52, 0.1)';
          this.colorStyle = 'rgb(255, 255, 255)';
          this.display = 'none';
        } else {
          this.backgroundStyle = 'rgb(22, 35, 52)';
          this.colorStyle = 'rgb(255, 255, 255)';
          this.display = 'flex';
        }
      } else if (this.getPath() === 'SEZ' && this.isMobile) {
        this.display = 'flex';
        this.backgroundStyle = 'rgb(255, 255, 255)';
        this.colorStyle = 'rgb(0, 0, 0)';
       //console.log('conditionalBackground() sez page & isMobile');
      } else if (this.getPath() === 'SEZ'){
        this.backgroundStyle = 'rgb(22, 35, 52)';
        this.colorStyle = 'rgb(255, 255, 255)';
        this.display = 'flex';
      } else {
        this.display = 'flex';
       //console.log('conditionalBackground() sez page');
      }
    },
    navigateToMap() {
      this.$router.push('/map');
    }
  },
  mounted() {
    /**
     * When the user navigates to the advanced search page we need the navbar background color to change.
     * The advanced search page will emit a signal 
     * Register the listen for that signal here.
     */
    this.$root.$on('advanced-search-navigation', this.conditionalBackground)
    /**
     * When the user navigates to the map page we need the navbar background color to change.
     * The advanced map page will emit a signal 
     * Register the listen for that signal here.
     */
    this.$root.$on('map-navigation', this.conditionalBackground)
    /**
     * When the user navigates to the SEZ page we need the navbar to disappear.
     * The SEZ page will emit a signal 
     * Register the listen for that signal here.
     */
    /**
     * REMOVED 7/19/2021
     */
    this.$root.$on('sez-navigation', this.conditionalBackground)
  },
  beforeDestroy() {
    /**
     * Clean up event listener
     */
    this.$root.$off('advanced-search-navigation', this.conditionalBackground)
    this.$root.$off('map-navigation', this.conditionalBackground)
    this.$root.$off('sez-navigation', this.conditionalBackground)
  }
}
</script>

<style scoped lang="scss">
  .navbar{
    svg {
      color: rgb(79, 103, 130);
      width: 2.5rem;
      height: 2.5rem;
    }
    svg:hover {
      color: rgb(167, 135, 109);
    }
    .border {
      border: 2px solid !important;
      border-color: rgb(79, 103, 130) !important;
    }
    .border:hover {
      border-color: rgb(167, 135, 109) !important;;
    }
  }
  .navbar-expand {
    //justify-content: space-between !important;
    justify-content: space-between !important;
    display: flex;
  }

  .nav-item {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .quick-search {
    // transform: scale(0.7);
    div {
      margin-right: auto;
    }
  }
  
  div.middle-item {
    a.navbar-brand {
      div {
        h1, h3, p {
          display: inline;
        }
        p {
          // padding-left: 0.5rem;
          color: rgb(167, 135, 109);
          font-weight: 600;
          font-family: 'Roboto', sans-serif;
          margin-bottom: unset;
        }
      }
    }
  }

  .end-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .navbar-brand {
      margin-right: unset;
    }
    a {
      svg {
        margin-left: auto;
      }
    }
  }

  .exit-sez {
      //margin-right: 1rem;
      //margin-top: 2rem;
      color: rgb(167, 135, 109);
      //transform: scale(2);
      cursor: pointer;
      z-index: 1;
  }
</style>