<template>
  <div class="text-right filter-control-style">
    <b-row class="filter-control-row-style">
      <b-col class="text-center bread-crumbs">
        <b-row>
          <div v-if="advancedSearch.keyWord" class="pulser"></div>
          <p
            v-if="advancedSearch.keyWord"
            class="custom-breadcrumb"
          > {{advancedSearch.keyWord}}
          </p>
          <div v-if="advancedSearch.region" class="pulser"></div>
          <p
            v-if="advancedSearch.region"
            class="custom-breadcrumb"
          > {{advancedSearch.region}}
          </p>
          <div v-if="advancedSearch.zone_type" class="pulser"></div>
          <p
            v-if="advancedSearch.zone_type"
            class="custom-breadcrumb"
          > {{advancedSearch.zone_type}}
          </p>
          <div v-if="advancedSearch.zone_specialization" class="pulser"></div>
          <p
            v-if="advancedSearch.zone_specialization"
            class="custom-breadcrumb"
          > Specialization {{advancedSearch.zone_specialization}}
          </p>
          <div v-if="advancedSearch.management_type" class="pulser"></div>
          <p
            v-if="advancedSearch.management_type"
            class="custom-breadcrumb"
          > {{advancedSearch.management_type}}
          </p>
          <div v-if="advancedSearch.activity" class="pulser"></div>
          <p
            v-if="advancedSearch.activity"
            class="custom-breadcrumb"
          > {{advancedSearch.activity}}
          </p>
          <div v-if="advancedSearch.size_hectares[0] != null" class="pulser"></div>
          <p
            v-if="advancedSearch.size_hectares[0] != null"
            class="custom-breadcrumb"
          > ha {{advancedSearch.size_hectares[0]}} - {{advancedSearch.size_hectares[1]}}
          </p>
          <div v-if="advancedSearch.size_null" class="pulser"></div>
          <p
            v-if="advancedSearch.size_null"
            class="custom-breadcrumb"
          > Unknown ha size included
          </p>
        </b-row>
        <b-row>
          <div v-if="portActive" class="pulser"></div>
          <p
            v-if="portActive"
            class="custom-breadcrumb"
          >Port within {{portDistance.min}} km - {{portDistance.max}} km</p>

          <div v-if="airportActive" class="pulser"></div>
          <p
            v-if="airportActive"
            class="custom-breadcrumb"
          >Airport within {{airportDistance.min}} km - {{airportDistance.max}} km</p>

          <div v-if="capitalActive" class="pulser"></div>
          <p
            v-if="capitalActive"
            class="custom-breadcrumb"
          >Capital within {{capitalDistance.min}} km - {{capitalDistance.max}} km</p>

          <div v-if="populousActive" class="pulser"></div>
          <p
            v-if="populousActive"
            class="custom-breadcrumb"
          >Metropole within {{populousDistance.min}} km - {{populousDistance.max}} km</p>
        </b-row>
        <b-row>
          <div v-if="countrySelectedOnMap" class="pulser"></div>
          <p
            v-if="countrySelectedOnMap"
            class="custom-breadcrumb"
          >Country Selected {{countrySelectedOnMap}}</p>
        </b-row>
      </b-col>
      <b-col class="text-center"> 
        <b-button v-if="isAdvancedSearchNeeded"
          id="clear-tags-btn" 
          variant="link"
          @click="clearTags()">
          <span>CLEAR ALL TAGS</span>
        </b-button>
      </b-col>
      <!-- <b-col></b-col>
      <b-col></b-col> -->
      <b-col 
        class="text-center"
        cols="2"
      >
        <!-- Show ctl -->
        <b-form-group>
          <b-button 
            class="filter-options-ctl shadow-none" 
            v-b-toggle.show-options-collapse 
            variant="link"
          >
            <span>SHOW</span>
            <BIconCaretDownFill />
            <BIconCaretUpFill />
          </b-button>
          <b-collapse 
            id="show-options-collapse"
            class="filter-options-group"
            v-model="showOptionsVisible"
          >
            <div
              @mouseleave="autoCloseOptions"
            >
              <b-form-checkbox-group class="text-left"
                v-model="show_selected"
                :options="show_options"
                value-field="value"
                @change="show_filter_selected"
                stacked
              ></b-form-checkbox-group>
            </div>
          </b-collapse>
        </b-form-group>
      </b-col>
      <b-col 
        class="text-center"
        cols="2"
      >
        <!-- zone type, management, or activity, filter ctl, needs to be radio buttons -->
        <b-form-group>
          <b-button 
            class="filter-options-ctl shadow-none" 
            v-b-toggle.show-colors-collapse 
            variant="link"
          >
            <span>COLOR LEGEND</span>
            <BIconCaretDownFill />
            <BIconCaretUpFill />
          </b-button>
          <b-collapse 
            id="show-colors-collapse"
            class="filter-options-group"
            v-model="showColorsVisible"
          >
          <div
            @mouseleave="autoCloseColors"
          >
            <b-form-radio-group class="text-left"
              v-model="color_selections"
              :options="color_options"
              @change="color_filter_selected"
              stacked
            ></b-form-radio-group>
          </div>
          </b-collapse>
        </b-form-group>
      </b-col>
      <b-col 
        class="text-center"
        cols="2"
      >
        <!-- sez type color ctl -->
        <b-form-group>
          <b-button
            class="filter-options-ctl shadow-none"
            v-b-toggle.show-filters-collapse
            variant="link"
          >
            <span>FILTERS</span>
            <BIconCaretDownFill />
            <BIconCaretUpFill />
          </b-button>
          <b-collapse 
            id="show-filters-collapse"
            class="filter-options-group"
            v-model="showFilterVisible"
          >
          <div
            @mouseleave="autoCloseFilters"
          >
            <b-form-checkbox-group class="text-left"
              v-model="filter_selections"
              :options="filter_options"
              value-field="value"
              @change="filter_filter_selected"
              stacked
            ></b-form-checkbox-group>
          </div>
          </b-collapse>
        </b-form-group>
      </b-col>
      <!-- <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col> -->
    </b-row>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BIconCaretDownFill, BIconCaretUpFill } from 'bootstrap-vue';
export default {
  computed: {
    ...mapGetters(['isAdvancedSearchNeeded']),
    ...mapState([
      'advancedSearch', 
      'colorLegendType',
      //'show_selected',
      'countrySelectedOnMap',
      //'filter_selections'
      'portActive',
      'portDistance',
      'airportActive',
      'airportDistance',
      'capitalActive',
      'capitalDistance',
      'populousActive',
      'populousDistance'
    ]),
    color_selections : {
      get() {
        return this.$store.state.color_selection;
      },
      set(filter) {
        this.$store.commit('setColorFilter', filter);
      }
    },
    show_selected : {
      get() {
        return this.$store.state.show_selected;
      },
      set(payload) {
        this.$store.commit('setShowFilter', payload);
      }
    }
  },
  components : {
    BIconCaretDownFill,
    BIconCaretUpFill
  },
  data() {
    return {
      //show_selected: [],
      show_options: [
        { text: 'Capitals', value: 'Capitals', checked: false},
        { text: 'Port', value: 'Port', checked: false},
        { text: 'Airports', value: 'Airports', checked: false},
        { text: 'Size by Hectare', value: 'Size', checked: false }
      ],
      //color_selections: [],
      color_options: [
        { text: 'Color by Zone Type', value: 'zone_type', checked: false},
        { text: 'Color by Zone Management Type', value: 'management_type', checked: false},
        { text: 'Color by Zone Activity Status', value: 'zone_activity', checked: false},
      ],
      filter_selections: [],
      filter_options: [
        { text: 'Specialized Economic Zones', value: 'sez', checked: false },
        { text: 'Export Processing Zones', value: 'epz', checked: false },
        { text: 'Free Trade Zones', value: 'ftz', checked: false },
        { text: 'Diversified Zone', value: 'dz', checked: false },
        { text: 'Economic Revitalization Zones', value: 'rrz', checked: false },
        { text: 'Charter Cities', value: 'cc', checked: false },
        { text: 'Foreign Trade Zones', value: 'ForeignTrade', checked: false},
        { text: 'Other Trade Zones', value: 'OtherZone', checked: false}
      ],
      showOptionsVisible: null,
      showColorsVisible: null,
      showFilterVisible: null
    }
  },
  props: [

  ],
  methods: {
    /**
     * When the @changed directive is passed a function name 
     * it will pass an array to that function invocation
     * 
     * The last item in that array is an observe object
     * The value of the elemtn changing will come before that last item
     * 
     */
    show_filter_selected(e) {
      let resize = this.show_options.filter(i => { return i.value === 'Size' })[0]
      e.includes("Size") ? resize.checked = true : resize.checked = false;
      this.$store.dispatch('updateUniformSEZsize', resize.checked);
      this.$emit("update-filters", 'show_filter_selected');
    },
    color_filter_selected(e) {
      this.$store.commit('setColorFilter', e);
      this.$emit("update-filters", "color_filter_selected");
    },
    filter_filter_selected(e) {
      this.$store.commit('setFilterSelections', e);
      this.filter_selections = this.$store.state.filter_selections;
      this.$emit("update-filters", 'filter_filter_selected');
    },
    get_all_filter_options() {
      let show_options_copy = this.show_options
      let color_options_copy = this.color_options
      let filter_options_copy = this.filter_options
      return {
        show_options: show_options_copy,
        color_options: color_options_copy,
        filter_options: filter_options_copy
      }
    },
    /**
     * UNUSED
     */
    clearFilters() {
      this.show_selected = []
      //this.color_selections = []
      this.filter_selections = []

      this.show_options = [
        { text: 'Capitals', value: 'Capitals', checked: false},
        { text: 'Port', value: 'Port', checked: false},
        { text: 'Airports', value: 'Airports', checked: false},
        { text: 'Size by Hectare', value: 'Size', checked: false }
      ]

      this.color_options = [
        { text: 'Color by zone type', value: 'zone_type', checked: false},
        { text: 'Color by zone Management type', value: 'management_type', checked: false},
        { text: 'Color by zone activity Status', value: 'zone_activity', checked: false},
      ]

      this.filter_options = [
        { text: 'Specialized Economic Zones', value: 'sez', checked: false},
        { text: 'Export Processing Zones', value: 'epz', checked: false},
        { text: 'Free Trade Zones', value: 'ftz', checked: false},
        { text: 'Diversified Zone', value: 'dz', checked: false},
        { text: 'Regional Revitalization Zone', value: 'rrz', checked: false},
        { text: 'Charter Cities', value: 'cc', checked: false},
        { text: 'Foreign Trade Zones', value: 'ForeignTrade', checked: false},
        { text: 'Other Trade Zones', value: 'OtherZone', checked: false}
      ]

      this.$emit("update-filters", 'clear') // send signal to clear and redraw map to parent component
    },
    clearTags() {
      this.$emit("update-filters", 'clear-tags')
    },
    // autoCloseFilterCtls() {
    //   this.$root.$emit('bv::toggle::colapse', 'show-options-collapse');
    //   this.$root.$emit('bv::toggle::colapse', 'show-colors-collapse');
    //   this.$root.$emit('bv::toggle::colapse', 'show-filters-collapse');
    // },
    autoCloseOptions() {
      setTimeout(() => {
        this.showOptionsVisible = false;
      }, 1000);
    },
    autoCloseColors() {
      setTimeout(() => {
        this.showColorsVisible = false;
      }, 1000);
    },
    autoCloseFilters() {
      setTimeout(() => {
        this.showFilterVisible = false;
      }, 1000);
    }
  },
  mounted() {
    this.filter_selections = this.$store.state.filter_selections;
  }
}
</script>

<style lang="scss" scoped>

  /* color set */
  $motifColor: rgb(167, 135, 109);
  

  .filter-control-style {
    background-color: rgb(22, 35, 52);
    color: #e5e5e5;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .filter-control-row-style {
    margin-left: 3rem;
  }

  #collapse-filter {
    position: absolute;
    z-index: 999;
  }

  .filter-col {
    background-color: rgba(7, 16, 26, 1);
  }

  .filter-options-group {
    background-color: rgb(34, 62, 80);
    // border: 1px solid rgba(0,0,0,0.50);
    position: absolute;
    color: #FFFFFF;
    z-index: 1;
    padding: 0.5rem;
    border-style: solid;
    border-color: $motifColor;
    border-width: 1px;
  }

  .filter-options-group:before {
    content: "";
    position: absolute;
    // z-index: 0;
    top: -0.35rem;
    left: -0.35rem;
    right: -0.35rem;
    bottom: -0.35rem;
    border: .15rem solid rgb(34, 62, 80);
    
  }

  .pulser {
    background: $motifColor;
    border-radius: 50%;
    margin: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;

    box-shadow: 0 0 0 0 $motifColor;
    transform: scale(1);
    animation: pulse 2s infinite;

    display: inline-block;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 $motifColor;
    } 70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.5rem rgba(41, 217, 194, 0);
    } 100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(41, 217, 194, 0);
    }
  }

  .custom-breadcrumb {
    display: inline-block;
    // line-height: 1rem;
    margin-bottom: 0rem;
  }

  .filter-options-ctl {
    color: #e5e5e5 !important;
    font-size: 11.5px !important;
    svg {
      transform: scale(0.5);
    }
    padding-left: unset;
    padding-right: unset;
    margin-left: unset;
    margin-right: unset;
  }

  .text-center {
    padding-left: unset;
    padding-right: unset;
  }

  .form-group {
    margin-bottom: 0rem;
    .btn-link {
      // padding-left: 1rem;
      // padding-right: 1rem;
      width: auto;
      span {
        height: 2rem;
        width: auto;
        letter-spacing: 2px;
      }
    }
    // button {
    //   padding-left: unset;
    //   padding-right: unset;
    // }
    button[aria-expanded="true"] {
      svg.bi-caret-down-fill {
        display: none;
      }
    }
    button[aria-expanded="false"] {
      svg.bi-caret-up-fill {
        display: none;
      }
    }
    button:hover,
    button:focus {
      text-decoration: none !important;
    }
  }

  #clear-tags-btn {
    color: $motifColor;
  }
</style>