<template>
  <div id="sez-modal-wrapper">
    <div id="sez-modal-page">
      <div>
        <b-container fluid>
          <!-- <b-row v-if="isMobile" class="exit-row">
            <BIconXCircle @click="navigateBack()" />
          </b-row> -->
          <b-row>
            <b-col class="left-column-container">
              <b-row>
                <div class="text-left">
                  <p class="region-nation">
                    {{getSezPageData.region}} / {{getSezPageData.country}}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="text-left">
                  <label class="zone-name">{{getSezPageData.zone_name}}</label>
                </div>
              </b-row>
              <b-row>
                <ul>
                  <li class="zone-attributes">
                    {{getSezPageData.zone_type}}
                  </li>
                  <li class="zone-attributes">
                    {{getSezPageData.country}}
                  </li>
                  <li class="zone-attributes">
                    {{getSezPageData.management_type}}
                  </li>
                  <li class="zone-attributes">
                    {{getSezPageData.status}}
                  </li>
                </ul>
              </b-row>
              <!-- <b-row>
                <div class="text-left">
                  <b-link :href="getSezPageData.url">Navigate to URL</b-link>
                </div>
              </b-row> -->
              <b-row>
                <div class="text-left">
                  <iframe 
                    :src="getGMapsIframeURL" 
                    :width="getIframeWidth()" 
                    :height="getIframeHeight()" 
                    frameborder="0" 
                    style="border:0;" 
                    allowfullscreen="" 
                    aria-hidden="false" 
                    tabindex="0"></iframe>
                </div>
              </b-row>
            </b-col>
            <b-col class="right-column-container">
              <b-row>
                <div class="text-left">
                  <h3>ZONE TYPE</h3>
                  <p class="sez-content">{{getSezPageData.zone_type}}</p>
                </div>
              </b-row>
              <b-row v-if="sezPageData.zone_specialization">
                <div class="text-left">
                  <h3>ZONE SPECIALIZATION</h3>
                  <p class="sez-content">{{sezPageData.zone_specialization}} </p>
                </div>
              </b-row>
              <b-row>
                <div class="text-left">
                  <h3>MANAGEMENT TYPE</h3>
                  <p class="sez-content">{{getSezPageData.management_type}}</p>
                </div>
              </b-row>
              <b-row>
                <div class="text-left">
                  <h3>MANAGEMENT COMPANY</h3>
                  <p class="sez-content">{{getSezPageData.management_company}}</p>
                </div>
              </b-row>
              <b-row>
                <div class="text-left">
                  <h3>ACTIVITY STATUS</h3>
                  <p class="sez-content">{{getSezPageData.status}}</p>
                </div>
              </b-row>
              <!-- <b-row>
                <div class="text-left">
                  <h3>SIZE</h3>
                  <p class="sez-content">{{getSezPageData.size_hectares}} Hectares</p>
                </div>
              </b-row> -->
              <b-row>
                <div class="text-left">
                  <h3>ZONE WEBSITE</h3>
                  <p class="sez-content text-primary" @click="openLink">{{getSezPageData.url}}</p>
                </div>
              </b-row>
              <b-row>
                <div class="text-left">
                  <h3>LEGAL FRAMEWORK</h3>
                  <p class="sez-content">{{getSezPageData.sez_framework}}</p>
                </div>
              </b-row>
              <b-row>
                <div class="text-left">
                  <h3>DISTANCE TO</h3>
                  <p class="sez-content">Nearest Port: {{ Math.floor(getSezPageData.nearest_port_distance_km * 100) / 100 }} km</p>
                  <p class="sez-content">Nearest Airport: {{ Math.floor(getSezPageData.nearest_airport_distance_km * 100 ) / 100}} km</p>
                  <p class="sez-content">Nearest Populous City: {{ Math.floor(getSezPageData.populous_city_distance_km * 100) / 100}} km</p>
                  <p class="sez-content">Capital City: {{ Math.floor(getSezPageData.capital_city_distance_km * 100) / 100 }} km</p>      
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
//import { BIconXCircle } from 'bootstrap-vue';
export default {
  metaInfo() {
    return { 
      title: `${this.getSezPageData.zone_name} - ${this.getSezPageData.country}`,
      meta: [
        { name: 'description', content:  `${this.getSezPageData.zone_name}, ${this.getSezPageData.zone_type}, ${this.getSezPageData.country}, ${this.getSezPageData.region}`},
        { property: 'og:title', content: `${this.getSezPageData.zone_name} - ${this.getSezPageData.country}`},
      ]
    }
  },
  components: {
    //BIconXCircle
  },
  data() {
    return {
      dataFetched : false
    }
  },
  methods: {
    openLink() {
     //console.log('openLink', this.getSezPageData.url);
      const httpsRegex = new RegExp(/^https:\/\//);
      const httpRegex = new RegExp(/^http:\/\//);
      if (httpsRegex.test(this.getSezPageData.url)) {
        window.open(
          this.getSezPageData.url, '_blank'
        );
      } else if (httpRegex.test(this.getSezPageData.url)) {
        window.open(
          this.getSezPageData.url, '_blank'
        );
      } else {
        // no http:// or https:// present
      }
      window.open(
        `https://${this.getSezPageData.url}`, '_blank'
      );
    },
    getIframeWidth() {
      if(this.isMobile) {
        return window.innerWidth * 0.91;
      } else {
        return window.innerWidth * 0.66;
      }
    },
    getIframeHeight() {
      if(this.isMobile) {
        return window.innerHeight * 0.35;
      } else {
        return window.innerHeight * 0.66;
      }
    },
    navigateBack() {
      this.$router.push({name: 'Map'});
    }
  },
  computed: {
    ...mapGetters(['getSezId','getSezPageData']),
    getGMapsIframeURL() {
      return `https://www.google.com/maps/embed/v1/view?key=${process.env.VUE_APP_GMAPS_APIKEY}&center=${this.getSezPageData.latitude},${this.getSezPageData.longitude}&zoom=15&maptype=roadmap`
    },
    ...mapState(['sezPageData', 'isMobile'])
  },
  async created() {
    let fixedCountryName = this.$route.params.country.replace(/_/gm, ' ');
    let fixedZoneName = this.$route.params.zoneName.replace(/_/gm, ' ');
    await this.$store.dispatch('fetchSezData', {
      country: fixedCountryName,
      zoneName: fixedZoneName
    });
  },
  async mounted() {
    /**
     * REMOVED 7/19/2021
     */
    this.$root.$emit('sez-navigation');
    //console.log('Modal.vue mounted()', this.$store.state.sezId);
    //console.log('mounted()', this.$route.params)

    // let fixedCountryName = this.$route.params.country.replace(/_/gm, ' ');
    // let fixedZoneName = this.$route.params.zoneName.replace(/_/gm, ' ');
    // await this.$store.dispatch('fetchSezData', {
    //   country: fixedCountryName,
    //   zoneName: fixedZoneName
    // });
    ////console.log('mounted()', this.$store.state.sezPageData, this.$store.state.sezId);
  }
}
</script>

<style lang="scss" scoped>
  #sez-modal-wrapper {
    max-width: 100%;
    background-color: rgb(255, 255, 255);
    #sez-modal-page {
      margin-bottom: 3rem;
      background-color: rgb(255, 255, 255);
      margin-right: 1rem;
    }
  }

  .exit-row {
    justify-content: flex-end;
    height: 3rem;
    svg {
      margin-right: 1rem;
      margin-top: 2rem;
      color: rgb(167, 135, 109);
      transform: scale(2);
      cursor: pointer;
      z-index: 1;
    }
  }

  #sez-modal-page > div > div > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > span {
    margin: 5px;
  }

@media (min-width: 426px) {
  .container-fluid {
    padding-top: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid rgb(167, 135, 109);
    .row:nth-child(-n+2) {
      margin-top: -1.0rem;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 425px) {
  #sez-modal-page {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }
  .container-fluid {
    padding-top: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    //border: 1px solid rgb(167, 135, 109);
    .row:nth-child(-n+2) {
      margin-top: -1.0rem;
      margin-bottom: 1rem;
    }
    .row {
      .left-column-container {
        .row {
          ul {
            li.zone-attributes {
              font-family: 'EBGaramond-Italic-VariableFont_wght' !important;
            }
          }
        }
      }
    }
  }
}

  .left-column-container {
    margin-right: 0.75rem !important;
    margin-left: 0.5rem;
    margin-top: 1rem;
    .region-nation {
      text-transform: uppercase;
      font-family: 'Roboto';
      color: rgb(0, 0, 0);
      font-size: 15px !important;
      letter-spacing: 2px;
    }
    .zone-name {
      font-family: 'EBGaramond-VariableFont_wght';
      font-size: 40px;
      font-weight: 900;
      color: rgb(0, 0, 0);
    }
    ul {
      padding-left: unset;
      .zone-attributes {
        color: rgb(167, 135, 109);
        font-size: 16px;
        font-style: italic;
        float: left;
        list-style-position: inside;
        list-style-type: disc;
        margin-right: 1rem;
      }
      .zone-attributes:nth-child(-n+1) {
        list-style-type: none;
      }
    }
  }

  .right-column-container {
    margin-left: 0.75rem !important;
    .row {
      border-bottom: 1px solid rgb(167, 135, 109);
      margin-right: 0px;
    }
    .row:nth-last-child(-n+1) {
      border-bottom: unset;
    }
    h3 { 
      font-family: 'Roboto' !important;
      font-size: 10px !important;
      letter-spacing: 2px;
      padding-top: 1rem;
      color: #2c3e50;
    }
    .sez-content {
      font-size: 18px !important;
      font-weight: normal !important;
      margin-bottom: 0px;
      margin-top: 0px; 
      font-family: 'EBGaramond-VariableFont_wght' !important;
      color: rgb(0, 0, 0);
    }
  }

  .custom-badge {
    background-color: rgba(216, 216, 216,0.55);
    border: 1px solid #979797;
    border-radius: 8px;
    font-family: 'EBGaramond-VariableFont_wght';
    font-size: 18px;
    color: #4A4A4A;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }

  .text-primary {
    cursor: pointer;
  }

</style>