import Vue from 'vue';
import Router from 'vue-router';
//import store from './store';
import Home from './views/Home.vue';
import CartoMap from './views/CartoSEZMap.vue';
import SezPage from './components/Modal.vue';
import AdvancedSearch from './views/AdvancedSearch3.vue';
//import Protected from './views/Protected.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        description: "Mapping the World's Special Economic Zones"
      }
    },
    {
      path: '/map',
      name: 'Map',
      component: CartoMap,
      meta: {
        title: 'Open Zone Map',
        // requiresAuth: true
      }
    },
    {
      path: '/SEZ/:country/:zoneName',
      name: 'SEZ',
      component: SezPage,
      meta: {
        description: 'Zone Name, Zone Type, Country, Region',
        // requiresAuth: true
      }
    },
    {
      path: '/Advanced',
      name: 'Advanced Search',
      component: AdvancedSearch,
      meta: {
        title: 'Advanced SEZ Search',
        // requiresAuth: true
      }
    },
    // {
    //   path: '/Password',
    //   name: 'Password',
    //   component: Protected
    // }
  ]
});

// router.beforeEach((to, from, next) => {
//   if(to.meta.requiresAuth) {
//     store.dispatch('updateIntendedTo', to.path);
//     if(store.getters.passwordAuthorized) {
//       next();
//     } else {
//       next('/password');
//     }
//   } else {
//     next();
//   }
// });

export default router;