<template>
  <div id="advanced-search-3">
    <div class="mobile-search-page" v-if="isMobile">
      <div class="mobile-search-wrapper container">
        <div class="mobile-search">
          <b-input-group>
            <b-form-input
              v-on:input="updateQuickSearch"
              :value="quickSearchString"
              :placeholder="placeholder"
              class="mobile-search-input"
            ></b-form-input>
            <b-input-group-append>
              <label>
                <BIconSearch/>
              </label>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="mobile-catgeory-selectors text-left container">
        <div
          v-for="(item, key) in menu" 
          :key="key"
        >
          <div class="mobile-category-selector">
            <b-button 
              variant="custom-btn shadow-none"
              v-b-toggle="item.paramString"
            >
              {{item.header}}
            </b-button>
            <BIconChevronRight class="chevron-right"/>
          </div>
          <b-collapse :id="item.paramString">
            <criteria-selector 
              v-if="item.paramString === 'region'"
              :choices="regions" 
              :selection="advCriteriaSelect" 
              parameterString="region"
            ></criteria-selector>
            <criteria-selector
              v-if="item.paramString === 'zoneType'"
              :choices="zoneType"
              :selection="advCriteriaSelect"
              parameterString="zone_type"
            ></criteria-selector>
            <criteria-selector
              v-if="item.paramString === 'zoneSpecialization'"
              :choices="specializationChoices"
              :selection="advCriteriaSelect"
              parameterString="zone_specialization"
            ></criteria-selector>
            <criteria-selector
              v-if="item.paramString === 'managementType'"
              :choices="managementType"
              :selection="advCriteriaSelect"
              parameterString="management_type"
            ></criteria-selector>
            <div 
              v-if="item.paramString === 'distanceTo'"
              v-bind:class="{revealed: criteria.distanceTo.active, closed: !criteria.distanceTo.active}">
              <!-- Port Distance -->
              <div class="slide-wrapper">
                <label>
                  Distance to nearest port
                </label>
                <b-row>
                  <b-col>
                    Min {{portsDistance.min}} KM
                  </b-col>
                  <b-col>
                    Max {{portsDistance.max}} KM
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <VueSlider
                      v-model="portsDistance"
                      :min="portMinMax[0]"
                      :max="portMinMax[1]"
                    ></VueSlider>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="setPortMinDistance"
                      type="number"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col>
                    <b-input
                      v-model="setPortMaxDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                </b-row>
              </div>
              <!-- Airport Distance -->
              <div class="slide-wrapper">
                <label>
                  Distance to nearest airport
                </label>
                <b-row>
                  <b-col v-if="airportsDistance">
                    min {{airportsDistance.min}} KM
                  </b-col>
                  <b-col>
                    max {{airportsDistance.max}} KM
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <VueSlider
                      v-model="airportsDistance"
                      :min="airportMinMax[0]"
                      :max="airportMinMax[1]"
                    ></VueSlider>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <b-input
                      v-model="setAirportMinDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-input
                      v-model="setAirportMaxDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                </b-row>
              </div>
              <!-- Capital Distance -->
              <div class="slide-wrapper">
                <label>
                  Distance to capital
                </label>
                <b-row>
                  <b-col v-if="capitalDistance">
                    Min {{capitalDistance.min}} KM
                  </b-col>
                  <b-col>
                    Max {{capitalDistance.max}} KM
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <VueSlider
                      v-model="capitalDistance"
                      :min="capitalMinMax[0]"
                      :max="capitalMinMax[1]"
                    ></VueSlider>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <b-input
                      v-model="setCapitalMinDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-input
                      v-model="setCapitalMaxDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                </b-row>
              </div>
              <div class="slide-wrapper">
                <label>
                  Distance to most populous city
                </label>
                <b-row>
                  <b-col v-if="populousDistance">
                    Min {{populousDistance.min}} KM
                  </b-col>
                  <b-col>
                    Max {{populousDistance.max}} KM
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <VueSlider
                      v-model="populousDistance"
                      :min="populousMinMax[0]"
                      :max="populousMinMax[1]"
                    ></VueSlider>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <b-input
                      v-model="setPopulousMinDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-input
                      v-model="setPopulousMaxDistance"
                      type="number"
                    >
                    </b-input>
                  </b-col>
                </b-row>
              </div>
            </div>
            <criteria-selector
              v-if="item.paramString === 'activityStatus'"
              :choices="active"
              :selection="advCriteriaSelect"
              parameterString="activity"
            ></criteria-selector>
          </b-collapse>
          <b-row 
            v-if="advancedSearch.region && item.paramString === 'region'"
          >
            <b-button 
              class="custom-tag"
              @click="removeCriteria('region')"
            >
              {{advancedSearch.region}}
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="advancedSearch.zone_type && item.paramString === 'zoneType'"
          >
            <b-button 
              class="custom-tag"
              @click="removeCriteria('zone_type')"
            >
              {{advancedSearch.zone_type}}
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="advancedSearch.zone_specialization && item.paramString === 'zoneSpecialization'"
          >
            <b-button 
              class="custom-tag"
              @click="removeCriteria('zone_specialization')"
            >
              {{advancedSearch.zone_specialization}}
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="advancedSearch.management_type && item.paramString === 'managementType'"
          >
            <b-button 
              class="custom-tag"
              @click="removeCriteria('management_type')"
            >
              {{advancedSearch.management_type}}
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="advancedSearch.activity && item.paramString === 'activityStatus'"
          >
            <b-button 
              class="custom-tag"
              @click="removeCriteria('activity')"
            >
              {{advancedSearch.activity}}
              <BIconX />
            </b-button>
          </b-row>
          <b-row v-if="isSizeHectares">
            <b-button 
              class="custom-tag"
              @click="removeCriteria('size_hectares')"  
            >
              {{advancedSearch.size_hectares}}
              <BIconX />
            </b-button>
          </b-row>
          <b-row v-if="advancedSearch.size_null">
            <b-button 
              class="custom-tag"
              @click="removeCriteria('size_null')"  
            >
              SEZ's of unknown size included
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="portActive && item.paramString === 'distanceTo'"
          >
            <b-button 
              class="custom-tag"
              @click="removePort()"  
            >
              port {{ portDistance.min }}km - {{ portDistance.max }}km
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="airportActive && item.paramString === 'distanceTo'"
          >
            <b-button 
              class="custom-tag"
              @click="removeAirport()"  
            >
              airport {{ airportDistance.min }}km - {{ airportDistance.max }}km
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="capitalActive && item.paramString === 'distanceTo'"
          >
            <b-button 
              class="custom-tag"
              @click="removeCapital()"  
            >
              capital city {{ capitalDistance.min }}km - {{ capitalDistance.max }}km
              <BIconX />
            </b-button>
          </b-row>
          <b-row 
            v-if="populousActive && item.paramString === 'distanceTo'"
          >
            <b-button 
              class="custom-tag"
              @click="removePopulous()"  
            >
              populous city {{ populousDistance.min }}km - {{ populousDistance.max }}km
              <BIconX />
            </b-button>
          </b-row>
        </div>
      </div>
      <div class="mobile-search-button-wrapper">
        <b-button 
          class="search-button"
          to="/Map"
          @click="goToMap()"
        >Search</b-button>
      </div>
    </div>
    <div v-else>
      <b-sidebar 
        title="sidebar" 
        visible
        no-header
        bg-variant="custom"
      >
        <div 
          class="sidebar-item text-left"
          v-for="(item, key) in menu" 
          :key="key"
          v-bind:class="{selected: criteria[`${item.paramString}`].active}"
        >
          <div 
            class="primary-category-2" 
            v-if="item.bicon === 'geo-alt'"
          >
            <BIconGeoAlt/>
          </div>
          <div class="primary-category-2" v-if="item.bicon === 'building'">
            <BIconBuilding/>
          </div>
          <div class="primary-category-2" v-if="item.bicon === 'building2'" >
            <b-img 
              :src="building2"
            ></b-img>
          </div>
          <div class="primary-category-2" v-if="item.bicon === 'group'">
            <b-img
              :src="group"
            ></b-img>
          </div>
          <div class="primary-category-2" v-if="item.bicon === 'distance'">
            <b-img
              :src="path"
            ></b-img>
          </div>
          <div class="primary-category-2" v-if="item.bicon === 'power'">
            <BIconPower/>
          </div>
          <div class="primary-category-2" v-if="item.bicon === 'arrow-angle'">
            <BIconArrowsAngleExpand
              v-if="item.bicon === 'arrow-angle'"
            />
          </div>
          <b-button 
            variant="custom-btn shadow-none"
            @click="reveal({id: item.paramString})"
          >
            <!-- <label>
              {{item.header}}
            </label> -->
            {{item.header}}
          </b-button>
          <BIconChevronRight class="chevron-right"/>
        </div>
      </b-sidebar>
      <div class="middle-ui">
        <b-row class="region-row">
          <criteria-selector 
            :choices="regions" 
            :selection="advCriteriaSelect" 
            parameterString="region"
            v-bind:class="{revealed: criteria.region.active, closed: !criteria.region.active}"
          ></criteria-selector>
        </b-row>
        <b-row class="region-row">
          <criteria-selector
            :choices="zoneType"
            :selection="advCriteriaSelect"
            parameterString="zone_type"
            v-bind:class="{revealed: criteria.zoneType.active, closed: !criteria.zoneType.active}"
          ></criteria-selector>
        </b-row>
        <b-row>
          <criteria-selector
            :choices="specializationChoices"
            :selection="advCriteriaSelect"
            parameterString="zone_specialization"
            v-bind:class="{revealed: criteria.zoneSpecialization.active, closed: !criteria.zoneSpecialization.active}"
          ></criteria-selector>
        </b-row>
        <b-row>
          <criteria-selector
            :choices="managementType"
            :selection="advCriteriaSelect"
            parameterString="management_type"
            v-bind:class="{revealed: criteria.managementType.active, closed: !criteria.managementType.active}"
          ></criteria-selector>
        </b-row>
        <b-row>
          <div v-bind:class="{revealed: criteria.distanceTo.active, closed: !criteria.distanceTo.active}">
            <div class="slide-wrapper">
              <label>
                Distance to nearest Port
              </label>
              <b-row>
                <b-col>
                  Min {{portsDistance.min}} KM
                </b-col>
                <b-col>
                  Max {{portsDistance.max}} KM
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col>
                  <VueSlider
                    v-model="portsDistance"
                    :min="portMinMax[0]"
                    :max="portMinMax[1]"
                  ></VueSlider>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-input
                    v-model="setPortMinDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
                <b-col>
                  <b-input
                    v-model="setPortMaxDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
              </b-row>
            </div>
            <div class="slide-wrapper">
              <label>
                Distance to nearest Airport
              </label>
              <b-row>
                <b-col v-if="airportsDistance">
                  Min {{airportsDistance.min}} KM
                </b-col>
                <b-col>
                  Max {{airportsDistance.max}} KM
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col>
                  <VueSlider
                    v-model="airportsDistance"
                    :min="airportMinMax[0]"
                    :max="airportMinMax[1]"
                  ></VueSlider>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-input
                    v-model="setAirportMinDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
                <b-col>
                  <b-input
                    v-model="setAirportMaxDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
              </b-row>
            </div>
            <div class="slide-wrapper">
              <label>
                Distance to Capital
              </label>
              <b-row>
                <b-col v-if="capitalDistance">
                  Min {{capitalDistance.min}} KM
                </b-col>
                <b-col>
                  Max {{capitalDistance.max}} KM
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col>
                  <VueSlider
                    v-model="capitalDistance"
                    :min="capitalMinMax[0]"
                    :max="capitalMinMax[1]"
                  ></VueSlider>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-input
                    v-model="setCapitalMinDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
                <b-col>
                  <b-input
                    v-model="setCapitalMaxDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
              </b-row>
            </div>
            <div class="slide-wrapper">
              <label>
                Distance to most populous city
              </label>
              <b-row>
                <b-col v-if="populousDistance">
                  Min {{populousDistance.min}} KM
                </b-col>
                <b-col>
                  Max {{populousDistance.max}} KM
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col>
                  <VueSlider
                    v-model="populousDistance"
                    :min="populousMinMax[0]"
                    :max="populousMinMax[1]"
                  ></VueSlider>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-input
                    v-model="setPopulousMinDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
                <b-col>
                  <b-input
                    v-model="setPopulousMaxDistance"
                    type="number"
                  >
                  </b-input>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-row>
        <b-row>
          <criteria-selector
            :choices="active"
            :selection="advCriteriaSelect"
            parameterString="activity"
            v-bind:class="{revealed: criteria.activityStatus.active, closed: !criteria.activityStatus.active}"
          ></criteria-selector>
        </b-row>
        <b-row>
          <div
            v-bind:class="{revealed: criteria.size.active, closed: !criteria.size.active}"
          >
            <div class="slide-wrapper">
              <label>
                SEZ HECTARE SIZE
              </label>
              <b-row class="size-labels">
                <b-col v-if="getAdvancedSearchParameters.size_hectares">
                  Min {{getAdvancedSearchParameters.size_hectares[0]}}
                </b-col>
                <b-col v-if="getAdvancedSearchParameters.size_hectares">
                  Max {{getAdvancedSearchParameters.size_hectares[1]}}
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col>
                  <VueSlider
                    v-model="size_hectares"
                    :min="sizes[0]"
                    :max="sizes[1]"
                  ></VueSlider>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-input
                    v-model="size_hectares[0]"
                    type="number"
                  >

                  </b-input>
                </b-col>
                <b-col>
                  <b-input
                    v-model="size_hectares[1]"
                    type="number"
                  >
                  </b-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox switch
                    disabled
                    v-model="size_nulls"
                  >
                    Include SEZ's without size info?
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
            
          </div>
        </b-row>
      </div>
      <div class="right-ui">
        <ActiveTags/>
      </div>
    </div>
  </div>
</template>
<script>
import { 
  BIconChevronRight, 
  BIconGeoAlt, 
  BIconBuilding, 
  BIconPower,
  BIconSearch
  //BIconArrowsAngleExpand 
} from 'bootstrap-vue';
import building2 from '../assets/building2v2.svg';
import group from '../assets/Group.svg';
import path from '../assets/path.svg';
import ActiveTags from '../components/ActiveTags.vue';
import { sez_table } from '../static';
import CriteriaSelector from '../components/CriteriaSelectorPrimary2.vue';
//import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    BIconChevronRight,
    BIconGeoAlt,
    BIconBuilding,
    BIconPower,
    //BIconArrowsAngleExpand,
    ActiveTags,
    CriteriaSelector,
    //VueSlider,
    BIconSearch
  },
  computed: {
    ...mapGetters([
      'getAdvancedSearchParameters',
      'isAdvancedSearchNeeded'
    ]),
    ...mapState([
      'isMobile',
      'quickSearchString',
      'advancedSearch',
      'portActive',
      'portDistance',
      'airportActive',
      'airportDistance',
      'capitalActive',
      'capitalDistance',
      'populousActive',
      'populousDistance'
    ]),
    size_hectares: {
      get () { return this.$store.state.advancedSearch.size_hectares },
      set (value) { this.$store.commit('setSizeHectares', value) }
    },
    size_nulls: {
      get () { return this.$store.state.advancedSearch.size_nulls },
      set (value) { this.$store.commit('setSizeNulls', value) }
    },
    portsDistance: {
      get () { return this.$store.state.portDistance },
      set (value) { this.$store.commit('setPortDistance', value) }
    },
    setPortMinDistance: {
      get () {
        return this.$store.state.portDistance.min;
      },
      set (value) {
        let distance = this.$store.state.portDistance;
        distance.min = value;
        if(!distance.max || distance.max <= distance.min) {
          distance.max = this.portMinMax[1];
        }
        this.$store.dispatch('updatePortDistance', distance);
      }
    },
    setPortMaxDistance : {
      get () {
        return this.$store.state.portDistance.max;
      },
      set (value) {
        let distance = this.$store.state.portDistance;
        if (!distance.min || distance.min < 0) {
          distance.min = 1;
        }
        distance.max = value;
        if (distance.max <= distance.min) {
          distance.max = parseInt(distance.min) + 1;
        }
        this.$store.dispatch('updatePortDistance', distance);
      }
    },
    airportsDistance: {
      get () { return this.$store.state.airportDistance },
      set (value) { this.$store.commit('setAirportDistance', value) }
    },
    setAirportMinDistance: {
      get () {
        return this.$store.state.airportDistance.min;
      },
      set (value) {
        let distance = this.$store.state.airportDistance;
        distance.min = value;
        if(!distance.max || distance.max <= distance.min) {
          distance.max = this.airportMinMax[1];
        }
        this.$store.dispatch('updateAirPortDistance', distance);
      }
    },
    setAirportMaxDistance: {
      get () {
        return this.$store.state.airportDistance.max;
      },
       set (value) {
        let distance = this.$store.state.airportDistance;
        if (!distance.min || distance.min < 0) {
          distance.min = 1;
        }
        distance.max = value;
        if (distance.max <= distance.min) {
          distance.max = parseInt(distance.min) + 1;
        }
        this.$store.dispatch('updateAirPortDistance', distance);
       }
    },
    capitalDistance: {
      get () { return this.$store.state.capitalDistance },
      set (value) { this.$store.commit('setCapitalDistance', value) }
    },
    setCapitalMinDistance : {
      get () {return this.$store.state.capitalDistance.min },
      set (value) {
        let distance = this.$store.state.capitalDistance;
        distance.min = value;
        if(!distance.max || distance.max <= distance.min) {
          distance.max = this.capitalMinMax[1];
        } 
        this.$store.dispatch('updateCapitalDistance', distance);
      }
    },
    setCapitalMaxDistance : {
      get () {return this.$store.state.capitalDistance.max },
      set (value) {
        let distance = this.$store.state.capitalDistance;
        if (!distance.min || distance.min < 0) {
          distance.min = 1;
        }
        distance.max = value;
        if (distance.max <= distance.min) {
          distance.max = parseInt(distance.min) + 1;
        }
        this.$store.dispatch('updateCapitalDistance', distance);
      }
    },
    populousDistance: {
      get () { return this.$store.state.populousDistance },
      set (value) { this.$store.commit('setPopulousDistance', value) }
    },
    setPopulousMaxDistance: {
      get () { return this.$store.state.populousDistance.max },
      set (value) {
        let distance = this.$store.state.populousDistance;
        if (!distance.min || distance.min < 0) {
          distance.min = 1;
        }
        distance.max = value;
        if (distance.max <= distance.min) {
          distance.max = parseInt(distance.min) + 1;
        }
        this.$store.dispatch('updatePopulousDistance', distance);
        //console.log('setPopulousMaxDistance', distance, value);
      }
    },
    setPopulousMinDistance: {
      get () { return this.$store.state.populousDistance.min },
      set (value) {
        let distance = this.$store.state.populousDistance;
        distance.min = value;
        if(!distance.max || distance.max < distance.min) {
          distance.max = this.populousMinMax[1];
        }
        this.$store.dispatch('updatePopulousDistance', distance);
        //console.log('setPopulousMinDistance', distance, value);
      }
    },
    placeholder: {
      get() {
        var placeholder = this.$store.state.quickSearchString;
        if (placeholder) {
          return placeholder;
        } else {
          return 'Search for a zone';
        }
      },
      set(value) {
        this.$store.dispatch('updateKeywordSearchString', value);
      }
    },
    isSizeHectares() {
      if (
        this.advancedSearch.size_hectares[0] === null 
        && this.advancedSearch.size_hectares[1] === null) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {},
  data() {
    return {
      // left sidebar menu
      menu: [
        /**
         * @key paramString {String}, This needs to be the same as the key names this.criteria 
         * Which configures the middle UI
         */
        {
          header: 'Region',
          bicon: 'geo-alt',
          paramString: 'region'
        },
        {
          header: 'Zone Type',
          bicon: 'building',
          paramString: 'zoneType'
        },
        {
          header: 'Zone Specialization',
          bicon: 'building2',
          paramString: 'zoneSpecialization'
        },
        {
          header: 'Management Type',
          bicon: 'group',
          paramString: 'managementType'
        },
        {
          header: 'Distance To',
          bicon: 'distance',
          paramString: 'distanceTo'
        },
        {
          header: 'Activity Status',
          bicon: 'power',
          paramString: 'activityStatus'
        },
        // {
        //   header: 'Size',
        //   bicon: 'arrow-angle',
        //   paramString:'size'
        // }
      ],
      // middle UI config
      criteria: {
        region: {
          active: false
        },
        zoneType: {
          active: false
        },
        zoneSpecialization: {
          active: false
        },
        managementType: {
          active: false
        },
        distanceTo: {
          active: false
        },
        activityStatus: {
          active: false
        },
        size: {
          active: false
        }
      },
      building2: building2,
      group: group,
      path: path,
      // utility items
      advancedSearchString: null,
      // Pre populated drop down values
      active: [],
      sizes: [],
      regions: [],
      zoneType: [],
      managementType: [],
      portMinMax: [null, null],
      airportMinMax : [null, null],
      capitalMinMax: [null, null],
      populousMinMax: [null, null],
      specializationChoices: [],
    }
  },
  methods: {
    updateQuickSearch(e) {
      //this.$store.commit('setQuickSearchString', e);
      this.$store.dispatch('updateKeywordSearchString', e);
    },
    async getRegions() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      const query = `SELECT distinct region FROM ${sez_table} ORDER BY region ASC`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        requestOptions
      )

      const text = await response.json()
      let regions = text.rows.map((i) => {
        return i.region;
      })
      //console.log('getRegions()', regions);
      this.regions = await regions;
    },
    /** TODO: This needs to be changed so that it works together with the zone_type filters */
    async getZoneTypes() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      const query = `SELECT distinct zone_type FROM ${sez_table} ORDER BY zone_type ASC`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        requestOptions
      )

      const text = await response.json()
      // await text.rows.forEach(element => {
      //   element.zone_type = stringFixer(element.zone_type);
      // });
      let zonesTypes = text.rows.map((i) => {
        return i.zone_type
      })
      this.zoneType = zonesTypes;
    },
    async getZoneSpecailizationOptions() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      const query = `SELECT distinct zone_specialization FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        requestOptions
      )

      const text = await response.json()
      /**
       * Remove any empty and unwanted records
       */
      //console.log('getZoneSpecailizationOptions', text);
      for (var i = 0; i < text.rows.length; i++) {
        //console.log('getZoneSpecailizationOptions', i, text.rows[i]);
        if (text.rows[i].zone_specialization === "") {
          text.rows.splice(i, 1);
          i--;
        }
        if (text.rows[i].zone_specialization === "N/A") {
          text.rows.splice(i, 1);
          i--;
        }
      }
      text.rows
      let specializations = text.rows.map((i) => {
        return i.zone_specialization;
      })
      //console.log('getZoneSpecailizationOptions ', specializations);
      this.specializationChoices = specializations;
    },
    async getManagementTypes() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      const query = `SELECT distinct management_type FROM ${sez_table} ORDER BY management_type ASC`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        requestOptions
      )

      const text = await response.json()
      // await text.rows.forEach(element => {
      //   element.management_type = stringFixer(element.management_type);
      // });
      //console.log(text.rows);
      let managementTypes = text.rows.map((i) => {
        return i.management_type;
      })
      //console.log(managementTypes)
      this.managementType = managementTypes;
    },
    async getActive() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      const query = `SELECT distinct status FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        requestOptions
      )

      const text = await response.json()
      let actives = text.rows.map((i) => {
        return i.status;
      })
      //console.log("getActive", actives);
      this.active = actives;
    },
    async getSizes() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      const query = `SELECT MAX(size_hectares) FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        requestOptions
      )

      const text = await response.json()
      //console.log('getSizes()', text);
      let sizes = text.rows.map((i) => {
        return i.max;
      })
      //console.log('getSizes()', sizes);
      this.sizes = [1, sizes[0]];
    },
    async getPortDistance() {
      var opts = {
        method: 'GET',
        redirect: 'follow'
      }
      const query = `SELECT MAX(nearest_port_distance_km) FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        opts
      )
      const text = await response.json()
      this.portMinMax = [1, Math.ceil(text.rows[0].max)];
      //console.log('getPortDistance', this.portMinMax);
    },
    async getAirportDistance() {
      var opts = {
        method: 'GET',
        redirect: 'follow'
      }
      const query = `SELECT MAX(nearest_airport_distance_km) FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        opts
      )
      const text = await response.json()
      this.airportMinMax = [1, Math.ceil(text.rows[0].max)];
      //console.log('getAirportDistance', this.airportMinMax);
    },
    async getCapitalDistance() {
      var opts = {
        method: 'GET',
        redirect: 'follow'
      }
      const query = `SELECT MAX(capital_city_distance_km) FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        opts
      )
      const text = await response.json()
      this.capitalMinMax = [1, Math.ceil(text.rows[0].max)];
      //console.log('getCapitalDistance', this.capitalMinMax);
    },
    async getPopulousDistance() {
      var opts = {
        method: 'GET',
        redirect: 'follow'
      }
      const query = `SELECT MAX(populous_city_distance_km) FROM ${sez_table}`
      const url = `https://${process.env.VUE_APP_CARTO_USERNAME}.carto.com/api/v2/sql?api_key=${process.env.VUE_APP_CARTO_API_KEY}&q=${query}\n`
      const response = await fetch(
        url,
        opts
      )
      const text = await response.json()
      this.populousMinMax = [1, Math.ceil(text.rows[0].max)];
      //console.log('getPopulousDistance', this.populousMinMax);
    },
    /**
     * UNUSED
     */
    // clearAdvancedSearch() {
    //   this.$store.commit('unsetAdvancedSearchParameters')
    //   this.advancedSearchString = null;
    //   this.nearbyPortValue = [1,1];
    //   this.nearbyAirportValue = [1, 1];
    //   this.nearbyCapitalValue = [1, 1];
    //   this.nearbyPopulousValue = [1, 1];
    // },
    /**
     * This is passed into child components
     */
    advCriteriaSelect(e) {
      let payload = {};
      payload[`${e.parameterString}`] = e.item; // old style object property accessing mixed with string interpolation for dynamic key names
     //console.log('advCriteriaSelect', payload);
      this.$store.dispatch('updateAdvancedSearchParameters', payload)
    },
    reveal(params) {
      let uiID = params.id;
      this.criteria[`${uiID}`].active = true;
      let uis = {};
      Object.assign(uis, this.criteria);
      delete uis[`${uiID}`];
      let uisToHide = Object.keys(uis);
      uisToHide.forEach((i) => {
         this.criteria[`${i}`].active = false;
       })
    },
    unsetPortDistance() {
      this.$store.dispatch('unsetPortDistance');
    },
    unsetAirportDistance() {
      this.$store.dispatch('unsetAirportDistance');
    },
    unsetCapitalDistance() {
      this.$store.dispatch('unsetCapitalDistance');
    },
    unsetPopulousDistance() {
      this.$store.dispatch('unsetPopulousDistance');
    },
    removeCriteria(e) {
      this.$store.commit('nullifyAdvancedSearchParameter', e);
    },
    goToMap() {
      this.$router.push('/map');
    },
    removeDistance(e) {
      this.$store.commit('nullifyInfrastructureSearchParameters', e);
    },
    removePort() {
      this.$store.commit('unsetPortDistance');
    },
    removeAirport() {
      this.$store.commit('unsetAirportDistance');
    },
    removeCapital() {
      this.$store.commit('unsetCapitalDistance');
    },
    removePopulous() {
      this.$store.commit('unsetPopulousDistance');
    }
  },
  mounted() {
    /**
     * We need to tell the navbar component to change it's background
     */
    this.$root.$emit('advanced-search-navigation');
    this.getRegions();
    this.getZoneTypes();
    this.getZoneSpecailizationOptions();
    this.getManagementTypes();
    this.getActive();
    this.getSizes();
    this.getPortDistance();
    this.getAirportDistance();
    this.getCapitalDistance();
    this.getPopulousDistance();
  }
}
</script>

<style lang="scss">
// OVERRIDE
#app::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
#app {
  overflow-y: scroll; /* Add the ability to scroll */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  // Desktop Styles
  @media screen and (min-width: 767px) {
    #advanced-search-3 {
      overflow-y: hidden;
    }
  }
  #advanced-search-3 {
    background-color: #fff !important;
    position: relative;
    height: 100vh;
    .b-sidebar-outer {
      .b-sidebar {
        background-color: #fff;
        margin-top: 5.15rem;
        width: 33vw;
        .b-sidebar-body {
          display: inline-block;
          margin-top: 3rem;
          .sidebar-item {
            padding-left: 3vw;
            font-family: 'EBGaramond-VariableFont_wght';
            height: 4rem;
            width: 100%;
            margin: unset;
            .primary-category-2 {
              vertical-align: -10%;
              display: inline-block;
              padding: 0.5rem;
              border: 2px solid rgb(167, 135, 109);
              border-radius: 2.55rem;
              height: 65%;
              width: auto;
              img {
                //vertical-align: 0%;
                padding-bottom: 0.2rem;
                height: 1.5rem;
              }
            }
            svg:first-child {
              height: 1.25rem;
              width: 1.25rem;
              color: rgb(167, 135, 109);
            }
            .btn {
              font-size: 2rem;
            }
            .chevron-right {
              //height: 1.25rem;
              vertical-align: -0.4rem;
              transform: scale(1.5);
            }
          }
          .selected {
            background-color: #ececec;
          }
        }
      }
    }
    .middle-ui {
      color: #000;
      margin-left: 33%;
      //padding-left: 0.9rem;
      width: 33vw;
      position: relative;
      background-color: #ececec;
      height: 100vh;
      .row {
        //margin-left: 1.5vw;
        width: inherit;
        .revealed {
          padding-top: 3rem;
          //background-color: #ececec;
          //position: absolute;
          overflow-x: hidden;
          overflow-y: auto;
          // top: 0rem;
          width: inherit;
          height: 80vh;
          // bottom: 0rem;
          .slide-wrapper {
            padding-top: 1.5rem;
            padding-left: 3vw;
            padding-right: 1.5vw;
            padding-bottom: 1.5rem;
            border-style: solid;
            border-color: #fff;
            border-width: 1px;
            label {
              font-size: 18px;
            }
            .size-labels {
              min-height: 1.5rem;
            }
            .row {
              .vue-slider {
                //background-color: #000;
                .vue-slider-rail {
                  //background-color: #000;
                  color: #000;
                }
              }
              .col {
                input, .custom-control {
                  margin-top: 1rem;
                }
              }
            }
          }
        }
        .closed {
          display: none;
        }
      }
    }
    .right-ui {
      position: absolute;
      float: left;
      color: #000;
      margin-left: 64.75%;
      width: 35.25vw;
      height: 100vh;
      //padding-left: 3rem;
      top: 0rem;
      background-color: rgb(245, 245, 245);
    }
  }
  .bg-custom {
    background-color: #fff !important;
  }
  .btn-custom-btn, .btn-custom-btn:active {
    //background-color: #fff !important;
    margin-right: unset;
    margin-left: unset;
  }
  // Mobile Styles
  #advanced-search-3 {
    .mobile-search-page {
      div {
        color: #000;
      }
      .mobile-search-wrapper {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        background-color: #F6F6F6;
        .mobile-search {
          .input-group {
            border: 1px solid;
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-color: rgb(79, 103, 130);
            .input-group-append {
              label {
                display: flex;
                justify-content: center;
                margin-bottom: unset;
                svg {
                  color: rgb(79, 103, 130);
                  width: 1.5rem;
                  height: auto;
                }
              }
            }
          }
          .mobile-search-input::placeholder {
            color: rgb(79, 103, 130);
            font-family: 'EBGaramond-VariableFont_wght' !important;
            font-size: 1.5rem;
          }
          .mobile-search-input, .search-input:-internal-autofill-selected {
            padding: 0px;
            background-color: rgba(0, 0, 0, 0) !important;
          }
          .mobile-search-input:focus {
            color: rgba(177.5, 177.5, 177.5, 0.9);
            box-shadow: none !important;
          }
          .mobile-search-input {
            border: unset;
          }
        }
      }
      .mobile-search-button-wrapper {
        a.search-button {
          position: fixed;
          display: flex;
          justify-content: center;
          //justify-content: left;
          color: rgb(255, 255, 255);
          width: 100%;
          background-color: rgb(79, 103, 130);
          font-family: 'EBGaramond-VariableFont_wght' !important;
          font-size: 2rem !important;
          border-radius: unset;
          padding-left: 4rem;
          padding-right: 4rem;
          bottom: 0%;
          margin-left: unset;
        }
      }
    }
    .mobile-catgeory-selectors {
      color: #000;
      padding-bottom: 8rem;
      background-color: #ffffff;
      .mobile-category-selector {
        font-family: 'EBGaramond-VariableFont_wght';
        height: 4rem;
        width: 100%;
        margin: unset;
        display: inline-block;
        border-radius: 2.55rem;
        height: 65%;
        width: auto;
        button.btn-custom-btn {
          font-size: 2rem;
        }
        button.btn {
          padding: unset;
        }
      }
      .chevron-right {
        vertical-align: -0.4rem;
        transform: scale(1.5);
        padding-left: 1rem;
        color: rgb(79, 103, 130);
      }
      .row {
        button {
          color: rgb(79, 103, 130);
          border-color: rgb(79, 103, 130);
          background-color: rgba(0, 0, 0, 0);
          border-radius: unset;
        }
      }
      #distanceTo {
        .slide-wrapper {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          border-top-style: solid;
          border-top-width: 1px;
          border-top-color: #F5F5F5;
        }
      }
      div.collapse {
        background-color: #ffffff;
      }
    }
  }

</style>