<template>
  <div id="mobile-filter-ctl">
    <label>FILTERS</label>
    <div>
      <b-form-checkbox-group
        v-model="filter_selections"
        :options="filter_options"
        value-field="value"
        @change="change_filter"
      ></b-form-checkbox-group>
    </div>
  </div>
</template>
<script>
export default {
  computed : {
    filter_selections : {
      get() {
        return this.$store.state.filter_selections;
      },
      set(e) {
       //console.log('computed.filter_selections.set()', e);
        this.$store.commit('setFilterSelections', e);
      }
    }
  },
  data() {
    return {
      filter_options: [
        { text: 'SPECIALIZED ECONOMIC ZONES', value: 'sez', checked: false },
        { text: 'EXPORT PROCESSING ZONES', value: 'epz', checked: false },
        { text: 'FREE TRADE ZONES', value: 'ftz', checked: false },
        { text: 'DIVERSIFIED ZONES', value: 'dz', checked: false },
        { text: 'ECONOMIC REVITLIZATION ZONES', value: 'rrz', checked: false },
        { text: 'CHARTER CITIES', value: 'cc', checked: false },
        { text: 'FOREIGN TRADE ZONES', value: 'ForeignTrade', checked: false},
        { text: 'OTHER TRADE ZONES', value: 'OtherZone', checked: false}
      ]
    }
  },
  methods : {
    change_filter(e) {
     //console.log('methods.change_filter()', e)
      this.$store.commit('setFilterSelections', e);
      this.$emit("update-filters", 'filter_filter_selected');
    }
  }
}
</script>
<style lang="scss">
#mobile-filter-ctl {
  height: 30rem;
  div {
    div {
      margin-left: 0.25rem;
      margin-top: -10px;
      div.custom-control-inline {
        margin-right: 5px;
      }
      /** Customization for checkbox styling (uncheked) */
      div.custom-checkbox > [type="checkbox"]:not(:checked) + label:before {
        background-color: rgb(255, 255, 255) !important;
        border-radius: unset !important;
      }
      /** Customization for checkbox styling (cheked)*/
      div.custom-checkbox > [type="checkbox"]:checked + label:before {
        background-color: rgb(167, 135, 109) !important;
      }
      div.custom-control {
        label.custom-control-label{
          font-size: 8px !important;
          letter-spacing: 0.5px !important;
          width: 39vw;
          span {
            margin-left: -5px;
            color: #000000;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
#mobile-filter-ctl > label {
  margin-bottom: unset;
  margin-left: 0.5rem;
}
</style>