<template>
  <div>
    <b-container fluid>
      <b-form @submit="quickSearch">
        <b-input-group size="lg">
          <b-form-input
            @input="updateQuickSearch"
            :value="quickSearchString"
            :placeholder="placeholder"
            class="search-input"
          />
          <b-input-group-append>
            <label v-if="getPath() == 'Map'"
              @click="quickSearchMap()">
              <b-icon-search/>
            </label>
            <label v-else
              @click="quickSearch()">
              <b-icon-search/>
            </label>
          </b-input-group-append>
        </b-input-group>
      </b-form>

    </b-container>
  </div>  
</template>
<script>
import {BIconSearch} from 'bootstrap-vue';
import {mapState} from 'vuex';
export default {
  name: 'SEZ-quick-search',
  props: {},
  components: {
    BIconSearch
  },
  computed: {
    ...mapState([
      'quickSearchString',
    ]),
    placeholder: {
      get() {
        var placeholder = this.$store.state.quickSearchString;
        if (placeholder) {
          return placeholder;
        } else {
          return 'Search for a zone';
        }
      },
      set(value) {
        this.$store.dispatch('updateKeywordSearchString', value);
      }
    }
  },
  data () {
    return {

    };
  },
  /**
   * TODO
   */
  methods: {
    async quickSearch() {
     //console.log('quickSearch');
      this.$router.push('/map')
      return;
    },
    quickSearchMap() {
      /**
       * We need to tell the map component to fire it's quicksearch() method
       */
      this.$root.$emit('mapQuickSearch');
    },
    updateQuickSearch(e) {
      //this.$store.commit('setQuickSearchString', e);
      this.$store.dispatch('updateKeywordSearchString', e);
    },
    getPath() {
      return this.$route.name;
    }
  },
  async mounted() {
    //console.log('QuickSearch Event: mounted::', this.getPath())
  }
}
</script>
<style scoped lang="scss">
.input-group {
  border: 1px solid;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-color: rgb(79, 103, 130);
  .search-input, .search-input:-internal-autofill-selected {
    padding: 0px;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .search-input::placeholder {
    color: rgb(79, 103, 130);
    font-family: 'EBGaramond-VariableFont_wght' !important;
  }
  .search-input:focus {
    color: rgba(177.5, 177.5, 177.5, 0.9);
    box-shadow: none !important;
  }
  label {
    //box-shadow: none !important;
    display: flex;
    justify-content: center;
    margin-bottom: unset;
    svg {
      color: rgb(79, 103, 130);
      width: 2rem;
      height: auto;
    }
  }
  label:hover, label:focus {
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    svg {
      color: #AC8A6C;
    }
  }
}
.form-control:focus {
  color: #fff;
}
.btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
</style>