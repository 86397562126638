<template>
  <div id="mobile-show-ctl">
    <label>SHOW</label>
    <div>
      <b-form-checkbox-group
        v-model="show_selected"
        :options="show_options"
        value-field="value"
        @change="change_show_selected"
      ></b-form-checkbox-group>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    show_selected : {
      get() {
        return this.$store.state.show_selected;
      },
      set(payload) {
        this.$store.commit('setShowFilter', payload);
      }
    }
  },
  data() {
    return {
      show_options : [
        { text: 'CAPITOLS', value: 'Capitals', checked: false},
        { text: 'PORT', value: 'Port', checked: false},
        { text: 'AIRPORTS', value: 'Airports', checked: false},
        { text: 'SIZE BY HECTARE', value: 'Size', checked: false }
      ]
    }
  },
  methods : {
    change_show_selected(e) {
      let resize = this.show_options.filter(i => { return i.value === 'Size' })[0]
      e.includes("Size") ? resize.checked = true : resize.checked = false;
      this.$store.dispatch('updateUniformSEZsize', resize.checked);
      this.$emit("update-filters", 'show_filter_selected');
    }
  }
}
</script>
<style lang="scss">
#mobile-show-ctl {
  border-bottom: 1px solid rgb(167, 135, 109);
  padding-bottom: 0.5rem;
  div {
    div {
      display: inline-block;
      margin-left: 0.25rem;
      margin-top: -10px;
      div.custom-control-inline {
        margin-right: 5px;
      }
      /** Customization for checkbox styling (uncheked) */
      div.custom-checkbox > [type="checkbox"]:not(:checked) + label:before {
        background-color: rgb(255, 255, 255) !important;
        border-radius: unset !important;
      }
      /** Customization for checkbox styling (cheked)*/
      div.custom-checkbox > [type="checkbox"]:checked + label:before {
        background-color: rgb(167, 135, 109) !important;
      }
      div.custom-control {
        label.custom-control-label{
          font-size: 8px !important;
          letter-spacing: 0.5px !important;
          span {
            margin-left: -5px;
            color: #000000;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
#mobile-show-ctl > label {
  margin-bottom: unset;
  margin-left: 0.5rem;
}
</style>