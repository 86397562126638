<template>
  <div>
    <div id="mobile-map-content" v-if="isMobile">
      <b-modal id="sez-modal" v-if="focusedSez" ref="my-modal" hide-footer > 
        <div id="mobile-modal-content" ref="mobile-modalcontent">
          <div class="outer-square">
            <div class="inner-square">
              <b-row class="mobile-modal-intro">
                <b-col class="mobile-zone-type" cols="10">
                  <label>
                    {{focusedSez.zoneType}}
                  </label>
                </b-col>
                <b-col class="mobile-modal-exit" cols="1">
                  <BIconXCircle class="modal-exit" @click="$bvModal.hide('sez-modal')"/>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mobile-modal-zonename">
                  <h1>
                    {{focusedSez.zoneName}}
                  </h1>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="zone-country">
                  <label>COUNTRY</label>
                  <label>
                    {{focusedSez.country}}
                  </label>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="zone-type">
                  <label>TYPE</label>
                  <label>
                    {{focusedSez.zoneType}}
                  </label>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col class="zone-size">
                  <label>SIZE</label>
                  <label>
                    {{ sizeConform(focusedSez.size) }}
                  </label>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col class="zone-management">
                  <label>MANAGEMENT</label>
                  <label>
                    {{focusedSez.management}}
                  </label>
                </b-col>
              </b-row>
              <b-row class="mobile-read-more">
                <b-button class="btn-read-more" @click="showSez">
                  Read More
                </b-button>
              </b-row>
            </div>
          </div>
        </div>
      </b-modal>
        <div 
          class="container" 
          id="mobile-map-navbar"
        >
          <b-row class="text-center">
            <b-col cols="2">
              <BIconSearch 
                v-on:click="showSearchMobile()"
                
              />
              <QuickSearchMobile/>
            </b-col>
            <b-col>
              <router-link
                to="/"
              >
                Open Zone Map
              </router-link>
              <p>BETA</p>
            </b-col>
            <b-col cols="2">
              <BIconJustify v-on:click="showMenu()"/>
              <Menu></Menu>
            </b-col>
          </b-row>
        </div>
        <div id="map-wrapper">
          <b-overlay :show="mapBusy">
            <div id="map"></div>
          </b-overlay>
        </div>
        <div
          id="mobile-clear-ctl-button"
        >
          <div v-if="isAdvancedSearchNeeded || uniformSEZsize || isMapFilteringNeeded || colorLegendType || color_selections.length > 0 || show_selected.length > 0 ||  quickSearchString" class="text-center">
            <BIconBackspaceFill @click="clearAdvancedSearch($event)"/>
            <p>CLEAR</p>
          </div>
        </div>
        <div
          id="right-filter-ctl"
          v-if="isMapFilteringNeeded | colorLegendType == 'management_type' | colorLegendType == 'zone_activity' | colorLegendType == 'zone_type'"
        >
          <div class="outer-half-circle">
            <div class="inner-half-circle">
            </div>
            <div class="inner-ctl"
              v-on:click="easeRightMobileLegend"
            >
              <BIconPlayFill/>
            </div>
          </div>
          
          <div class="mobile-legend-wrapper">
            <MobileColorLegendZoneType v-if="isMapFilteringNeeded & colorLegendType != 'management_type' & colorLegendType != 'zone_activity'"/>
            <MobileColorLegendZoneActivity v-if="colorLegendType === 'zone_activity'"/>
            <MobileColorLegendManagementType v-if="colorLegendType === 'management_type'"/>
          </div>
        </div>
        <div 
          id="bottom-ctl-screenout"
          v-on:click="raiseBottomMobileCtls"
        ></div>
        <div
          id="bottom-filter-ctl"          
        >
          <b-row>
            <b-col></b-col>
            <b-col cols="2">
              <div 
                class="bottom-ctl-toggle"
                v-on:click="raiseBottomMobileCtls"
              ></div>
            </b-col>
            <b-col></b-col>
          </b-row>
          <div 
            id="bottom-ctl-pane"
            class="text-left"
          >
            <ActiveTagsMobile @update-filters="filterMap"/>
            <CartoMapFilterShow @update-filters="filterMap"/>
            <CartoMapColorLegend @update-filters="filterMap"/>
            <CartoMapFiltersMobile @update-filters="filterMap"/>
          </div>
        </div>
    </div>
    <div v-else>
      <Menu></Menu>
      <div>
        <b-input-group>
          <!-- <router-link class="form-link" to="/Advanced">Advanced Search</router-link> -->
          <b-button class="btn-custom" to="/Advanced">ADVANCED SEARCH</b-button>
          <div id="clear-ctl-button">
            <div v-if="isAdvancedSearchNeeded || uniformSEZsize || isMapFilteringNeeded || colorLegendType || color_selections.length > 0 || show_selected.length > 0 ||  quickSearchString" class="text-center">
              <b-button variant="light" @click="clearAdvancedSearch($event)" >Reset Search & Filters</b-button>
            </div>
          </div>
        </b-input-group>
      </div>
      <div>
        <b-modal id="sez-modal" v-if="focusedSez" ref="my-modal" hide-footer >
          <div ref="modalcontent">
          <b-container fluid>
            <b-row class="title">
              <b-col class="zone-title" cols="11">
                <label>
                  {{focusedSez.zoneType}}
                </label>
                <h1>
                  {{focusedSez.zoneName}}
                </h1>
              </b-col>
              <b-col cols="1">
                <BIconXCircle class="modal-exit" @click="$bvModal.hide('sez-modal')"/>
              </b-col>
            </b-row>
            <b-row class="zone-info">
              <b-col class="zone-management">
                <label>MANAGEMENT</label>
                <label>
                  {{focusedSez.management}}
                </label>
              </b-col>
            </b-row>
            <b-row class="zone-info">
              <b-col>
                <label class="zone-country">
                  COUNTRY
                </label>
                <label>
                  {{focusedSez.country}}
                </label>
              </b-col>
              <b-col class="zone-type">
                <label>
                  TYPE
                </label>
                <label>
                  {{focusedSez.zoneType}}
                </label>
              </b-col>
              <b-col class="zone-management">
                <label>MANAGEMENT TYPE</label>
                <label>
                  {{focusedSez.managementType}}
                </label>
              </b-col>
              <!-- <b-col class="zone-size">
                <label>SIZE</label>
                <label>
                  {{ sizeConform(focusedSez.size) }}
                </label>
              </b-col> -->
            </b-row>
            <b-row class="read-more">
              <b-button class="btn-read-more" @click="showSez">
                Read More
              </b-button>
            </b-row>
          </b-container>
          </div>
        </b-modal>
        <!-- This is here so that rendering the page when no sez is chosen does not break the website -->
        <b-modal v-else ref="my-modal" hide-footer title="Using Component Methods"></b-modal>
      </div>
      <div>
        <MapFilter @update-filters="filterMap"/>
      </div>
      <div id="meta-map-wrapper">
        <ColorLegendZoneType v-if="isMapFilteringNeeded & colorLegendType != 'management_type' & colorLegendType != 'zone_activity'"></ColorLegendZoneType>
        <ColorLegendManagementType v-if="colorLegendType === 'management_type'"></ColorLegendManagementType>
        <ColorLegendZoneActivity v-if="colorLegendType === 'zone_activity'"></ColorLegendZoneActivity>
        <div id="map-wrapper">
          <b-overlay :show="mapBusy">
            <div id="map"></div>
          </b-overlay>
        </div>
        <div id="aux-map-items">
          <b-row class="aux-map-ctls">
            <b-col cols="3">
              <!-- <div v-if="isDemoNeeded" id="play-demo-button" @click="demoVideo($event)">
                <BIconPlayCircle id="demo-button"></BIconPlayCircle>
                <p>Play Guided Tour</p>
              </div> -->
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col cols="3">
              <!-- <div id="clear-ctl-button">
                <div v-if="isAdvancedSearchNeeded || uniformSEZsize || isMapFilteringNeeded || colorLegendType || color_selections.length > 0 || show_selected.length > 0 ||  quickSearchString" class="text-center">
                  <b-button variant="light" @click="clearAdvancedSearch($event)" >Reset Search & Filters</b-button>
                </div>
              </div> -->
            </b-col>
          </b-row>
        </div>
      </div>
      <div 
        class="custom-spacer-lighter">
      </div>
    </div>

  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import cartovl from '@carto/carto-vl';
import Menu from '../components/Menu.vue'
import { mapGetters, mapState } from 'vuex';
import { baseFields, sez_table } from '../static';
import { getMapLayer, stringFixer } from '../carto';
import MapFilter from '../components/CartoMapFilter';
import ActiveTagsMobile from '../components/ActiveTagsMobile.vue';
import QuickSearchMobile from '../components/QuickSearchMobile.vue';
import ColorLegendZoneType from '../components/ColorLegendZoneType';
import CartoMapFilterShow from '../components/CartoMapFilterShow.vue';
import CartoMapColorLegend from '../components/CartoMapColorLegend.vue';
import CartoMapFiltersMobile from '../components/CartoMapFiltersMobile.vue';
import ColorLegendZoneActivity from '../components/ColorLegendZoneActivity';
import ColorLegendManagementType from '../components/ColorLegendManagementType';
import MobileColorLegendZoneType from '../components/MobileColorLegendZoneType.vue';
import MobileColorLegendZoneActivity from '../components/MobileColorLegendZoneActivity.vue';
import MobileColorLegendManagementType from '../components/MobileColorLegendManagementType.vue';
import { 
  BIconXCircle,
  BIconSearch,
  BIconJustify,
  BIconBackspaceFill,
  BIconPlayFill
} from 'bootstrap-vue';
export default {
  name: 'SEZ-Map',
  props: {

  },
  watch : {
    isMobile: function () {
      setTimeout(() => {
        this.initMap()
      }, 500);
    }
  },
  computed: { 
    ...mapState([
      'advancedSearch', 
      'uniformSEZsize',
      'colorLegendType',
      'show_selected',
      'color_selections',
      'countrySelectedOnMap',
      'filter_selections',
      'isMobile'
    ]),
    quickSearchString: {
      get() {
        return this.$store.state.quickSearchString;
      },
      set(value) {
        this.$store.state.quickSearchString = value;
      }
    },
    ...mapGetters([
      'isAdvancedSearchNeeded',
      'isMapFilteringNeeded',
      'isLegendNeeded'
    ]),
    isDemoNeeded() {
      let demoNeeded = true;
      if (this.isAdvancedSearchNeeded) {
        demoNeeded = false;
      }
      if (this.colorLegendType) {
        demoNeeded = false;
      }
      if (this.show_selected.length > 0) {
        demoNeeded = false
      }
      if (this.color_selections.length > 0) {
        demoNeeded = false;
      }
      return demoNeeded;
    },
    countrySelectedOnMap : {
      get() {
        return this.$store.state.countrySelectedOnMap;
      },
      set(country) {
        this.$store.state.countrySelectedOnMap = country;
      }
    }
  },
  components: {
    MapFilter,
    //BIconChevronRight,
    ColorLegendZoneType,
    ColorLegendManagementType,
    ColorLegendZoneActivity,
    BIconXCircle,
    BIconSearch,
    BIconJustify,
    ActiveTagsMobile,
    QuickSearchMobile,
    CartoMapFilterShow,
    CartoMapColorLegend,
    CartoMapFiltersMobile,
    BIconBackspaceFill,
    BIconPlayFill,
    MobileColorLegendZoneType,
    MobileColorLegendZoneActivity,
    MobileColorLegendManagementType,
    Menu
  },
  data() {
    return {
      showSezPage: false,
      description:'SEZs',
      focusedSez: {
        id: null,
        zoneName: null,
        zoneType: null,
        country: null,
        management: null,
        managementType: null,
        specialization: null,
        size: null
      },
      map: null,
      rangeValue: null,
      result: false,
      managementCompanies: [],
      portChoices: [],
      colorLegendZoneType : null,
      colorLegendManagementType: null,
      colorLegendZoneActivity: null,
      mapBusy: true,
      mapLayersNeeded: 0, // We want to track the number of layers that need to load SEZ's
      mapLayersLoaded: 0 // keep track of the number that actually loaded
    }
  },
  methods: {
    // TODO: Debounce this
    calculateBottomCtlPosition() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
     //console.log('calculateBottomCtlPosition', vh);
    },
    showMenu() {
     //console.log('showMenu()')
      // make map disappear
      var mapElement = document.querySelector("#map-wrapper");
      mapElement.style.visibility = "hidden";
      // make menu appear
      var element = document.querySelector("#menu");
      element.style.visibility = "visible";
      var op = 0.1;  // initial opacity
      element.style.display = 'block';
      var timer = setInterval(
        function () {
          if (op >= 1){
            clearInterval(timer);
          }
          element.style.opacity = op;
          element.style.filter = 'alpha(opacity=' + op * 100 + ")";
          op += op * 0.1;
          //console.log('showMenu Interval Active', op);
        }, 
        10
      );
    },
    showSearchMobile() {
      var el = document.querySelector("#search-mobile-ctl");
      el.style.visibility = "visible";
      var opacity = 0.1;
      el.style.display = "block";
      var timer = setInterval(() => {
        if (opacity >= 1) {
          clearInterval(timer);
        }
        el.style.opacity = opacity;
        el.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
          opacity += opacity * 0.1;
          //console.log('show mobile search', opacity);
      }, 10);
    },
    raiseBottomMobileCtls() {
      let ctl = document.querySelector("#bottom-filter-ctl");
      let screenOut = document.querySelector("#bottom-ctl-screenout");
      // If element is already raised, lower it
      if(ctl.classList.contains("bottom-ctl-raised")) {
        ctl.classList.remove("bottom-ctl-raised");
        screenOut.style.visibility = 'hidden';
      } else {
        // element is lowered, raise it
        screenOut.style.visibility = 'visible';
        ctl.classList.add("bottom-ctl-raised");
      }
      //console.log('raiseBottomMobileCtls', ctl);
    },
    easeRightMobileLegend() {
      let ctl = document.querySelector("#right-filter-ctl");
      // If element is already raised, lower it
      if(ctl.classList.contains("right-filter-expanded")) {
        ctl.classList.remove("right-filter-expanded");
      } else {
        // element is lowered, raise it
        ctl.classList.add("right-filter-expanded");
      }
      //console.log('easeRightMobileLegend', ctl);
    },
    /**
     * /////////////////////////////////////////////////////////
     * This section defines utility functions used for 
     * conforming content displayed for SEZs
     * /////////////////////////////////////////////////////////
     */
    sizeConform(content) {
      if(!/\d/.test(content)) {
        return 'N/A'
      } else {
        if(content == 1) {
          return `${content} hectare`;
        } else {
          return `${Math.round(content)} hectares`;
        }
      }
    },
    truncateSEZContent(content) {
      if(!content) {return} // this will be called when there is no focused sez
      let num = 40;
      if (content.length <= num) {
        return content;
      }
      return content.slice(0, num) + '...';
    },
    truncateSEZTitle(content) {
      if(!content) {return} // this will be called when there is no focused sez
      let num = 50;
      if (content.length <= num) {
        return content;
      }
      return content.slice(0, num) + '...';
    },
    getIframeWidth() {
      if (window.innerWidth > 1800) {
        return window.innerWidth / 12;
      } else if (window.innerWidth > 1620) {
        return window.innerWidth / 10;
      } else if (window.innerWidth > 1439) {
        return window.innerWidth / 8;
      } else if (window.innerWidth > 1023) {
        return window.innerWidth / 8;
      } else if (window.innerWidth > 767) {
        return window.innerWidth / 8;
      } else if (window.innerWidth < 425 ) {
        return window.innerWidth / 8;
      }
    },
    getIframeHeight() {
      //console.log(window.innerHeight);
      if (window.innerHeight > 1000) {
        return window.innerHeight / 3;
      } else if (window.innerHeight > 900) {
        return window.innerHeight / 2.75;
      } else if (window.innerHeight > 800) {
        return window.innerHeight / 2.5;
      } else if (window.innerHeight > 700) {
        return window.innerHeight / 2.25;
      } else if (window.innerHeight > 600) {
        return window.innerHeight / 1.95;
      } else if (window.innerHeight > 500) {
        return window.innerHeight / 1.65;
      }
    },
    demoVideo() {
     //console.log('demo vidoe clicked', e);
      alert('Play the Video');
    },
    /**
     * /////////////////////////////////////////////////////////
     * THIS Section deals with adding and removing layers 
     * as a result of filterin requests by the user
     * /////////////////////////////////////////////////////////
     */
    clearAdvancedSearch() {
      this.$store.commit('unsetAdvancedSearchParameters');
      this.removeMapLayers();
      this.removeMapFilterLayers();
      this.$store.commit('setUniformSEZsize', false);
      this.$store.commit('unsetColorFilter');
      this.$store.commit('unsetShowFilter');
      this.$store.commit('unsetFilterSelections');
      //this.countrySelectedOnMap = false;
      this.$store.commit('unsetQuickSearch');
      this.$store.commit('unsetPortDistance');
      this.$store.commit('unsetAirportDistance');
      this.$store.commit('unsetCapitalDistance');
      this.$store.commit('unsetPopulousDistance');
      this.initMap();
    },
    // [X] regional revitalization zones
    async addRegionalRevitalizationZoneLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded) {
        theQuery = `${baseFields} WHERE zone_type = 'Economic Revitalization Project' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Economic Revitalization Project'`;
      }
      // add sez's that are regional revitalization zones
      const sourceRRZ = await new cartovl.source.SQL(
        theQuery, {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerRRZ = getMapLayer('rrz_sezs', sourceRRZ, {
        color: '#fee08b', // yellow
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerRRZ.addTo(this.map)
      this.addInteractivity(layerRRZ)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] diversified zones
    async addDiversifiedZoneLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded) {
        theQuery = `${baseFields} WHERE zone_type = 'Diversified Zone' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Diversified Zone'`;
      }
      // add sez's that are diversified zones
      const sourceDZ = await new cartovl.source.SQL(
        theQuery, {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerDZ = getMapLayer('dz_sezs', sourceDZ, {
        color: '#29d9c2', // cyan
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerDZ.addTo(this.map)
      this.addInteractivity(layerDZ)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] special economic zones
    async addSpecialEconomicZoneLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded) {
        theQuery = `${baseFields} WHERE zone_type = 'Specialized Zone' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Specialized Zone'`;
      }
     //console.log('addSpecialEconomicZoneLayer()', theQuery);
      // add sez's that are special economic zones
      const sourceSEZ = await new cartovl.source.SQL(
        theQuery, {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerSEZ = getMapLayer('sez_sezs', sourceSEZ, {
        color: '#b125de', // purple
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerSEZ.addTo(this.map)
      this.addInteractivity(layerSEZ)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] free trade zones
    async addFreeTradeZoneLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded) {
        theQuery = `${baseFields} WHERE zone_type = 'Free Trade Zone' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Free Trade Zone'`;
      }
      // add sez's that are free trade zones
      const sourceFTZ = await new cartovl.source.SQL(
        theQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerFTZ = getMapLayer('ftz_sezs', sourceFTZ, {
        color: '#6dd400', // green
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerFTZ.addTo(this.map)
      this.addInteractivity(layerFTZ)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] charter city
    async addCharterCityLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded){
        theQuery = `${baseFields} WHERE zone_type = 'Charter City' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Charter City'`;
      }
      // add sez's that are charter cities
      const sourceCC = await new cartovl.source.SQL(
        theQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerCC = getMapLayer('cc_sezs', sourceCC, {
        color: '#ed0245', // red
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerCC.addTo(this.map)
      this.addInteractivity(layerCC)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] export processing zones
    async addExportProcessingLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded){
        theQuery = `${baseFields} WHERE zone_type = 'Export Processing Zone' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Export Processing Zone'`;
      }
      // add sez's that export processing zones
      const sourceEPZ = await new cartovl.source.SQL(
        theQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerEPZ = getMapLayer('epz_sezs', sourceEPZ, {
        color: '#ff9666', // orange
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      if(!this.map.getLayer('epz_sezs')) {
        layerEPZ.addTo(this.map)
      }
      this.addInteractivity(layerEPZ)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [] Charter city zone type bug
    // Wait what was this for again?
    // is it a duplicate?
    async addCharterCityLayer_bug() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded){
        theQuery = `${baseFields} WHERE zone_type = 'Charter City' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Charter City'`;
      }
      const source = await new cartovl.source.SQL(
        theQuery, {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layer = getMapLayer('ccBUG_sezs', source, {
        color: '#fff',
        strokeColor: '#ed0245',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layer.addTo(this.map)
      this.addInteractivity(layer)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] ForeignTradeLayer
    async addForeignTradeLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded){
        theQuery = `${baseFields} WHERE zone_type = 'Foreign Trade Zone' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Foreign Trade Zone'`;
      }
      const source = await new cartovl.source.SQL(
        theQuery, {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layer = getMapLayer('foreignTrade_sez', source, {
        color: '#0a5fee',
        strokeColor: '#0a5fee',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layer.addTo(this.map)
      this.addInteractivity(layer)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    // [X] other type layer
    async addOtherTypeLayer() {
      let theQuery = null;
      if(this.isAdvancedSearchNeeded){
        theQuery = `${baseFields} WHERE zone_type = 'Other Zone' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
      } else {
        theQuery = `${baseFields} WHERE zone_type = 'Other Zone'`;
      }
      const source = await new cartovl.source.SQL(
        theQuery, {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layer = getMapLayer('other_sez', source, {
        color: '#fff',
        strokeColor: '#ed0245',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layer.addTo(this.map)
      this.addInteractivity(layer)
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 1;
    },
    async addZoneTypeLayer() {
      this.addFreeTradeZoneLayer()
      this.addCharterCityLayer()
      this.addExportProcessingLayer()
      this.addSpecialEconomicZoneLayer()
      this.addDiversifiedZoneLayer()
      this.addRegionalRevitalizationZoneLayer()
      this.addForeignTradeLayer()

      // added to show data bug
      //this.addCharterCityLayer_bug()
      this.addOtherTypeLayer()
    },
    async addManagementTypeLayer() {
      // add sez's that are management type private
      let privateQuery = null
      if (this.isAdvancedSearchNeeded || this.filter_selections.length > 0) {
        if (this.filter_selections.length > 0) {
          privateQuery = `${baseFields} WHERE management_type = 'Private' AND ${await this.$store.dispatch('crossApplyLegendWithZoneFilter', ({isActivityLayer: false}))}`;
        } else {
          privateQuery = `${baseFields} WHERE management_type = 'Private' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
        }
      } else {
        privateQuery = `${baseFields} WHERE management_type = 'Private'`;
      }
      //console.log('addManagementTypeLayer()', 'priavte query', privateQuery);
      const sourcePrivate = await new cartovl.source.SQL(
        privateQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      );
      const layerPrivate = getMapLayer( 'private_sez', sourcePrivate, {
        color: '#B125DE;',
        strokeColor: '#fff',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerPrivate.addTo(this.map);
      this.addInteractivity(layerPrivate);

      // add sez's that are management type public-private
      let publicPrivateQuery = null;
      if(this.isAdvancedSearchNeeded || this.filter_selections.length > 0) {
        if (this.filter_selections.length > 0) {
          publicPrivateQuery = `${baseFields} WHERE management_type = 'Public-Private Partnership' AND ${await this.$store.dispatch('crossApplyLegendWithZoneFilter', ({isActivityLayer: false}))}`;
        } else {
          publicPrivateQuery = `${baseFields} WHERE management_type = 'Public-Private Partnership' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
        }
      } else {
        publicPrivateQuery = `${baseFields} WHERE management_type = 'Public-Private Partnership'`;
      }
      //console.log('addManagementTypeLayer()', 'publicPrivateQuery', publicPrivateQuery);
      const sourcePublicPrivate = await new cartovl.source.SQL(
        publicPrivateQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      );
      const layerPublicPrivate = getMapLayer('publicPrivate_sezs', sourcePublicPrivate, {
        color: '#29D9C2;',
        strokeColor: '#fff',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerPublicPrivate.addTo(this.map);
      this.addInteractivity(layerPublicPrivate);

      // add sez's that are managment type public
      let publicQuery = null;
      if(this.isAdvancedSearchNeeded || this.filter_selections.length > 0) {
        if (this.filter_selections.length > 0) {
          publicQuery = `${baseFields} WHERE management_type = 'Public' AND ${await this.$store.dispatch('crossApplyLegendWithZoneFilter', ({isActivityLayer: false}))}`;
        } else {
          publicQuery = `${baseFields} WHERE management_type = 'Public' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
        }
      } else {
        publicQuery = `${baseFields} WHERE management_type = 'Public'`;
      }
      //console.log('addManagementTypeLayer()', 'publicQuery', publicQuery);
      const sourcePublic = await new cartovl.source.SQL(
        publicQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      );
      const layerPublic = getMapLayer('public_sezs', sourcePublic, {
        color: '#FEE08B;',
        strokeColor: '#fff',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerPublic.addTo(this.map);
      this.addInteractivity(layerPublic);
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 3;
    },
    async addZoneAcitivtyLayer() {
      // Add under development SEZs to map
      let udQuery = null;
      if(this.isAdvancedSearchNeeded || this.filter_selections.length > 0) {
        if (this.filter_selections.length > 0) {
          udQuery = `${baseFields} WHERE status = 'Under Development' AND ${await this.$store.dispatch('crossApplyLegendWithZoneFilter', ({isActivityLayer: true}))}`;
        } else {
          udQuery = `${baseFields} WHERE status = 'Under Development' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
        }
      } else {
        udQuery = `${baseFields} WHERE status = 'Under Development'`;
      }
      const sourceUD = await new cartovl.source.SQL(
        udQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerUD = getMapLayer('underDevelopment_sezs', sourceUD, {
        color: '#FEE08B', // yellow
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerUD.addTo(this.map)
      this.addInteractivity(layerUD)

      // Add active SEZs to map
      let acQuery = null;
      if(this.isAdvancedSearchNeeded || this.filter_selections.length > 0) {
        if (this.filter_selections.length > 0) {
          acQuery = `${baseFields} WHERE status = 'Active' AND ${await this.$store.dispatch('crossApplyLegendWithZoneFilter', ({isActivityLayer: true}))}`;
        } else {
          acQuery = `${baseFields} WHERE status = 'Active' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
        }
      } else {
        acQuery = `${baseFields} WHERE status = 'Active'`;
      }
      const sourceAC = await new cartovl.source.SQL(
        acQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerAC = getMapLayer('active_sezs', sourceAC, {
        color: '#6DD400', // Green 
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerAC.addTo(this.map)
      this.addInteractivity(layerAC)

      // Add inactive SEZs to map 
      let inQuery = null;
      if(this.isAdvancedSearchNeeded || this.filter_selections.length > 0) {
        if (this.filter_selections.length > 0) {
          inQuery = `${baseFields} WHERE status = 'Inactive' AND ${await this.$store.dispatch('crossApplyLegendWithZoneFilter', ({isActivityLayer: true}))}`;
        } else {
          inQuery = `${baseFields} WHERE status = 'Inactive' AND ${await this.$store.dispatch('composeAdvancedSearchWhereConditions')}`;
        }
      } else {
        inQuery = `${baseFields} WHERE status = 'Inactive'`;
      }
      const sourceIN = await new cartovl.source.SQL(
        inQuery,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const layerIN = getMapLayer('inactive_sezs', sourceIN, {
        color: '#ED0245', // red
        strokeColor: '#000',
        strokeWidth: '1',
        sezsPresent: true,
        dynamicSizing: this.uniformSEZsize
      },
      this.isMobile);
      layerIN.addTo(this.map);
      this.addInteractivity(layerIN);
      // This next lines hlps keep track oh knowing how many layers need to be loaded
      this.mapLayersNeeded = this.mapLayersNeeded + 3;
    },
    /**
     * Load all capitals even if advanced search criteria specified
     */
    async addCapitalsLayer() {
      const source = await new cartovl.source.SQL(
        `select distinct capital_city, cartodb_id, ST_Transform(CDB_LatLng(capitallat, capitallong), 3857) AS the_geom_webmercator from ${sez_table}`,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      /**
       * We set the color and stroke-color to all zeros, 
       * especially te alpha channel to make the point disappear, 
       * we want only the label of te capital name to appear
       */
      const viz = new cartovl.Viz(`
        color: rgba(0,0,0,0)
        strokeColor: rgba(0,0,0,0)
        strokeWidth: 1
        @v_features: viewportFeatures($capital_city)
      `)
      const layer = new cartovl.Layer('capitals-layer', source, viz)

      /**
       * Adding labels
       */
      layer.on('loaded', () => {
        this.map.addSource('capital-labels', { type: 'geojson', data: null });
        const labelSource = this.map.getSource('capital-labels');

        const layerUpdated = function () {
          const features = viz.variables.v_features.value;
          const geojsonFeatures = features.map(feature => {
            return {
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": feature.getRenderedCentroid()
              },
              "properties": {
                "label_field": `${feature.properties['capital_city']}`,
              }
            }
          });

          labelSource.setData({
            type: 'FeatureCollection',
            features: geojsonFeatures
          });
        };

        layer.on('updated', layerUpdated);

        // Style labels
        this.map.addLayer({
          "id": "my-capital-labels",
          "type": "symbol",
          "source": "capital-labels",
          "layout": {
            "text-field": "{label_field}",
            "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
            "text-size": 10,
            "text-offset": [0, 0.65],
            "text-anchor": "top",
            "text-max-width": 8,
            "text-justify": "center"
          },
          "paint": {
            "text-color": "white",
            "text-halo-color": "black",
            "text-halo-width": 0.5,
            "text-halo-blur": 0.5
          }
        });
      })

      layer.addTo(this.map)
    },
    /**
     * Load all ports even if advanced search criteria specified
     */
    async addPortsLayer() {
      const source = await new cartovl.source.SQL(
        `select distinct nearest_port, cartodb_id, ST_Transform(CDB_LatLng(portlat, portlong), 3857) AS the_geom_webmercator from ${sez_table}`,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const viz = new cartovl.Viz(`
        color: rgba(0,0,0,0)
        strokeColor: rgba(0,0,0,0)
        strokeWidth: 1
        @v_features: viewportFeatures($nearest_port)
      `)
      const layer = new cartovl.Layer('ports-layer', source, viz)

      /**
       * Adding labels
       */
      layer.on('loaded', () => {
        this.map.addSource('port-labels', { type: 'geojson', data: null });
        const labelSource = this.map.getSource('port-labels');

        const layerUpdated = function () {
          const features = viz.variables.v_features.value;
          const geojsonFeatures = features.map(feature => {
            return {
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": feature.getRenderedCentroid()
              },
              "properties": {
                "label_field": `${feature.properties['nearest_port']}`,
              }
            }
          });

          labelSource.setData({
            type: 'FeatureCollection',
            features: geojsonFeatures
          });
        };

        layer.on('updated', layerUpdated);

        // Style labels
        this.map.addLayer({
          "id": "my-port-labels",
          "type": "symbol",
          "source": "port-labels",
          "layout": {
            "text-field": "{label_field}",
            "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
            "text-size": 10,
            "text-offset": [0, 0.65],
            "text-anchor": "top",
            "text-max-width": 8,
            "text-justify": "center"
          },
          "paint": {
            "text-color": "blue",
            "text-halo-color": "white",
            "text-halo-width": 0.5,
            "text-halo-blur": 0.5
          }
        });
      })

      layer.addTo(this.map)
    },
    /**
     * Load all airports even if advanced search criteria specified
     */
    async addAirportsLayer() {
      const source = await new cartovl.source.SQL(
        `select distinct nearest_airport, cartodb_id, ST_Transform(CDB_LatLng(airportlat, airportlong), 3857) AS the_geom_webmercator from ${sez_table}`,{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      )
      const viz = new cartovl.Viz(`
        color: rgba(0,0,0,0)
        strokeColor: rgba(0,0,0,0)
        strokeWidth: 1
        @v_features: viewportFeatures($nearest_airport)
      `)
      const layer = new cartovl.Layer('airports-layer', source, viz)

      /**
       * Adding labels
       */
      layer.on('loaded', () => {
        this.map.addSource('airport-labels', { type: 'geojson', data: null });
        const labelSource = this.map.getSource('airport-labels');

        const layerUpdated = function () {
          const features = viz.variables.v_features.value;
          const geojsonFeatures = features.map(feature => {
            return {
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": feature.getRenderedCentroid()
              },
              "properties": {
                "label_field": `${feature.properties['nearest_airport']}`,
              }
            }
          });

          labelSource.setData({
            type: 'FeatureCollection',
            features: geojsonFeatures
          });
        };

        layer.on('updated', layerUpdated);

        // Style labels
        this.map.addLayer({
          "id": "my-airport-labels",
          "type": "symbol",
          "source": "airport-labels",
          "layout": {
            "text-field": "{label_field}",
            "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
            "text-size": 10,
            "text-offset": [0, 0.65],
            "text-anchor": "top",
            "text-max-width": 8,
            "text-justify": "center"
          },
          "paint": {
            "text-color": "cyan",
            "text-halo-color": "purple",
            "text-halo-width": 0.5,
            "text-halo-blur": 0.5
          }
        });
      })

      layer.addTo(this.map)
    },
    async filterMap(filtersData) {
      //console.log('filterMap', filtersData);
      switch (filtersData) {
        case 'clear-tags':
          this.clearAdvancedSearch()
          break;
      }
      //this.removeMapFilterLayers();
      //this.removeMapLayers();
      //this.initMap(); // <-- Why filters are not applying to quick search
      this.doLayering();
    },
    async showSez() {
      this.$refs['my-modal'].hide()
      //await this.$store.dispatch('fetchSezData', this.focusedSez.id)
      let fixedCountryName = null;
      fixedCountryName = this.focusedSez.country.replace(/\s/gm, '_');
     //console.log('showSez()', fixedCountryName);
      let fixedZoneName = null;
      //console.log('showSez()',this.focusedSez.zoneName)
      fixedZoneName = this.focusedSez.zoneName.replace(/\s/gm, '_');
      //console.log('showSez()', fixedZoneName);
      this.$router.push({path: `SEZ/${fixedCountryName}/${fixedZoneName}`});
    },
    /**
     * //////////////////////////////////////////////////////////
     * This section controls hiding and showing the SEZ modal
     * //////////////////////////////////////////////////////////
     */
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    /**
     * REMOVED
     */
    advCriteriaSelect(e) {
      this.$store.dispatch('updateAdvancedSearchParameters', e)
    },
    /**
     * //////////////////////////////////////////////////////////
     * This section mainly defines utility functions for 
     * removing clusters of map layers
     * //////////////////////////////////////////////////////////
     */
    removeMapLayers() {
      // remove layers and sources before adding our results to the map again
      if(this.map.getLayer('populated_places')) {this.map.removeLayer('populated_places')}
      if(this.map.getSource('populated_places')) {this.map.removeSource('populated_places')}
      if(this.map.getLayer('searched_places')) {this.map.removeLayer('searched_places')}
      if(this.map.getSource('searched_places')) {this.map.removeSource('searched_places')}
      if(this.map.getLayer('filtered_sezs')) {this.map.removeLayer('filtered_sezs')}
      if(this.map.getSource('filtered_sezs')) {this.map.removeSource('filtered_sezs')}
      if(this.map.getLayer('countriesLayer')) {this.map.removeLayer('countriesLayer')}
      if(this.map.getSource('countriesLayer')) {this.map.removeSource('countriesLayer')}
      if(this.map.getLayer('oceansLayer')) {this.map.removeLayer('oceansLayer')}
      if(this.map.getSource('oceansLayer')) {this.map.removeSource('oceansLayer')}
    },
    removeMapCapitalFilterLayers() {
      //'capitals-layer'
      if(this.map.getLayer('capitals-layer')) {this.map.removeLayer('capitals-layer')}
      if(this.map.getSource('capitals-layer')) {this.map.removeSource('capitals-layer')}
      //'my-capital-labels'
      if(this.map.getLayer('my-capital-labels')) {this.map.removeLayer('my-capital-labels')}
      if(this.map.getSource('my-capital-labels')) {this.map.removeSource('my-capital-labels')}
      //'capital-labels'
      if(this.map.getLayer('capital-labels')) {this.map.removeLayer('capital-labels')}
      if(this.map.getSource('capital-labels')) {this.map.removeSource('capital-labels')}
    },
    removeMapAirportFilterLayers() {
      //airports-layer
      if(this.map.getLayer('airports-layer')) {this.map.removeLayer('airports-layer')}
      if(this.map.getSource('airports-layer')) {this.map.removeSource('airports-layer')}
      //'my-airport-labels'
      if(this.map.getLayer('my-airport-labels')) {this.map.removeLayer('my-airport-labels')}
      if(this.map.getSource('my-airport-labels')) {this.map.removeSource('my-airport-labels')}
      //'airport-labels'
      if(this.map.getLayer('airport-labels')) {this.map.removeLayer('airport-labels')}
      if(this.map.getSource('airport-labels')) {this.map.removeSource('airport-labels')}
    },
    removeMapPortFilterLayers() {
      //'ports-layer'
      if(this.map.getLayer('ports-layer')) {this.map.removeLayer('ports-layer')}
      if(this.map.getSource('ports-layer')) {this.map.removeSource('ports-layer')}
      //'my-port-labels'
      if(this.map.getLayer('my-port-labels')) {this.map.removeLayer('my-port-labels')}
      if(this.map.getSource('my-port-labels')) {this.map.removeSource('my-port-labels')}
      //'port-labels'
      if(this.map.getLayer('port-labels')) {this.map.removeLayer('port-labels')}
      if(this.map.getSource('port-labels')) {this.map.removeSource('port-labels')}
    },
    removeMapFilterByActivityStatus() {
      //'underDevelopment_sezs'
      if(this.map.getLayer('underDevelopment_sezs')) {this.map.removeLayer('underDevelopment_sezs')}
      if(this.map.getSource('underDevelopment_sezs')) {this.map.removeSource('underDevelopment_sezs')}
      //'active_sezs'
      if(this.map.getLayer('active_sezs')) {this.map.removeLayer('active_sezs')}
      if(this.map.getSource('active_sezs')) {this.map.removeSource('active_sezs')}
      //'inactive_sezs'
      if(this.map.getLayer('inactive_sezs')) {this.map.removeLayer('inactive_sezs')}
      if(this.map.getSource('inactive_sezs')) {this.map.removeSource('inactive_sezs')}
    },
    removeMapFilterByManagementType() {
      //'public_sezs'
      if(this.map.getLayer('public_sezs')) {this.map.removeLayer('public_sezs')}
      if(this.map.getSource('public_sezs')) {this.map.removeSource('public_sezs')}
      //'publicPrivate_sezs'
      if(this.map.getLayer('publicPrivate_sezs')) {this.map.removeLayer('publicPrivate_sezs')}
      if(this.map.getSource('publicPrivate_sezs')) {this.map.removeSource('publicPrivate_sezs')}
      //'private_sezs'
      if(this.map.getLayer('private_sez')) {this.map.removeLayer('private_sez')}
      if(this.map.getSource('private_sez')) {this.map.removeSource('private_sez')}
    },
    removeMapFilterByZoneType() {
      //'ftz_sezs'
      if(this.map.getLayer('ftz_sezs')) {this.map.removeLayer('ftz_sezs')}
      if(this.map.getSource('ftz_sezs')) {this.map.removeSource('ftz_sezs')}
      //'cc_sezs'
      if(this.map.getLayer('cc_sezs')) {this.map.removeLayer('cc_sezs')}
      if(this.map.getSource('cc_sezs')) {this.map.removeSource('cc_sezs')}
      //'epz_sezs'
      if(this.map.getLayer('epz_sezs')) {this.map.removeLayer('epz_sezs')}
      if(this.map.getSource('epz_sezs')) {this.map.removeSource('epz_sezs')}
      //'sez_sezs'
      if(this.map.getLayer('sez_sezs')) {this.map.removeLayer('sez_sezs')}
      if(this.map.getSource('sez_sezs')) {this.map.removeSource('sez_sezs')}
      //'dz_sezs'
      if(this.map.getLayer('dz_sezs')) {this.map.removeLayer('dz_sezs')}
      if(this.map.getSource('dz_sezs')) {this.map.removeSource('dz_sezs')}
      //'rrz_sezs'
      if(this.map.getLayer('rrz_sezs')) {this.map.removeLayer('rrz_sezs')}
      if(this.map.getSource('rrz_sezs')) {this.map.removeSource('rrz_sezs')}
      // charter city DATA bug!
      if(this.map.getLayer('ccBUG_sezs')) {this.map.removeLayer('ccBUG_sezs')}
      if(this.map.getSource('ccBUG_sezs')) {this.map.removeSource('ccBUG_sezs')}

      if(this.map.getLayer('foreignTrade_sez')) {this.map.removeLayer('foreignTrade_sez')}
      if(this.map.getSource('foreignTrade_sez')) {this.map.removeSource('foreignTrade_sez')}

      if(this.map.getLayer('other_sez')) {this.map.removeLayer('other_sez')}
      if(this.map.getSource('other_sez')) {this.map.removeSource('other_sez')}
    },
    removeMapFilterLayers() {
      this.removeMapCapitalFilterLayers()
      this.removeMapPortFilterLayers()
      this.removeMapAirportFilterLayers()
      this.removeMapFilterByActivityStatus()
      this.removeMapFilterByManagementType()
      this.removeMapFilterByZoneType()
    },
    /**
     * //////////////////////////////////////////////////////////
     * This section defines functions that control how the layers
     * of the map behave
     * //////////////////////////////////////////////////////////
     */
    /**
     * Return a string that can be used to embed and render an Iframe
     */
    getGMapsIframeURL(latitude, longitude) {
      return `https://www.google.com/maps/embed/v1/view?key=${process.env.VUE_APP_GMAPS_APIKEY}&center=${latitude},${longitude}&zoom=15&maptype=satellite`
    },
    /**
     * This function will accept a carto map layer and add interactivity to each point on the layer
     * 
     * Add onClick event
     * Add on featureLeave event
     * Add on featureEnter event
     */
    addInteractivity(mapLayer) {
      // add featureClick interactivity to our map layer
      const interactivity = new cartovl.Interactivity(mapLayer)
      interactivity.on('featureClick', featureEvent => {
        const feature = featureEvent.features[0]
        if (!feature){
          return
        }
        // copy the data to show from feature clicked into our data object
        this.focusedSez = {
          zoneName: stringFixer(feature.variables.sezs._properties.zone_name),
          zoneType: stringFixer(feature.variables.sezs._properties.zone_type),
          id: feature.variables.sezs._properties.cartodb_id,
          country: feature.variables.sezs._properties.country,
          management: feature.variables.sezs._properties.management_company,
          specialization: feature.variables.sezs._properties.zone_specialization,
          size: feature.variables.sezs._properties.size_hectares,
          latitude: feature.variables.sezs._properties.latitude,
          longitude: feature.variables.sezs._properties.longitude,
          managementType: feature.variables.sezs._properties.management_type
        }
        this.showModal()
      })

      // feature Enter interactivity
      const enterInteractivity = new cartovl.Interactivity(mapLayer);
      enterInteractivity.on('featureEnter', f => {
        f.features[0].color.blendTo('rgba(188, 253, 2, 0.8)', 100);
      });
      // feature Leave interactivity
      const leaveInteractivity = new cartovl.Interactivity(mapLayer);
      leaveInteractivity.on('featureLeave', f => {
        f.features[0].color.reset();
      });
      // mapLayer.on('loaded', () => {
      //  //console.log('mapLayer loaded');
      //   this.mapLayersLoaded = this.mapLayersLoaded + 1;
      //   if(this.mapLayersLoaded >= this.mapLayersNeeded) {
      //     this.mapBusy = false;
      //   }
      // });
    },
    
    /**
     * REMOVED FROM USE
     */
    async addOceanLayer() {
      const oceanSource = await new cartovl.source.SQL(
        'select * FROM oceans_2', {
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      );
      const oceansViz = new cartovl.Viz(`
        color: rgba(0,0,0,0)
        strokeColor: rgba(0,0,0,0)
      `);
      const oceansLayer = new cartovl.Layer('oceansLayer', oceanSource, oceansViz);
      oceansLayer.addTo(this.map);
      const interactivity = new cartovl.Interactivity(oceansLayer);
      interactivity.on('featureClick', async featureEvent => {
        const feature = featureEvent.features[0];
        if(!feature) {return}
        // set country selected to null
        this.countrySelectedOnMap = null;
        this.doLayering();
        return;
      });
    },
    /**
     * REMOVED FROM USE
     */
    async addCountriesLayer() {
      const countriesSource = await new cartovl.source.SQL(
        'select * FROM ne_10m_admin_0_countries',{
          apiKey: process.env.VUE_APP_CARTO_API_KEY,
          user: process.env.VUE_APP_CARTO_USERNAME
        }
      );
      const countriesViz = new cartovl.Viz(`
        color: rgba(0,0,0,0)
        strokeColor: rgba(0,0,0,0)
        @country: $admin
      `);
      const countriesLayer = new cartovl.Layer('countriesLayer', countriesSource, countriesViz)
      countriesLayer.addTo(this.map)
      const interactivity = new cartovl.Interactivity(countriesLayer)
      interactivity.on('featureClick', async featureEvent => {
        const feature = featureEvent.features[0]
        if(!feature){ return }
        // CAVEAT: The country name returned from the Carto Admin DataSet may be slightly different from 
        // the ones on the SEZ DataSet, AG will have to undertake some data curation or 
        // we will have to do a mapping automagically here
        // if(feature.variables.country._properties.admin === 'United States of America') {
        //   this.countrySelectedOnMap = 'United States'
        // } else {
        //   this.countrySelectedOnMap = feature.variables.country._properties.admin
        // }
        this.countrySelectedOnMap = feature.variables.country._properties.admin
        //console.log('addCountriesLayer', this.countrySelectedOnMap);
        this.doLayering();
      });
    },
    /**
     * Fucking Ugliness this is
     */
    async doLayering() {
      this.mapBusy = true;
      let foo = async () => {
      this.removeMapFilterLayers();
      this.removeMapLayers();
      if(this.isMapFilteringNeeded) {
        /**
         * Is layer for color coding by zone type, management type or activity?
         */
        if(this.colorLegendType === 'zone_type') {
          this.addZoneTypeLayer();
        } else if (this.colorLegendType === 'management_type') {
          this.addManagementTypeLayer();
        } else if (this.colorLegendType === 'zone_activity') {
          this.addZoneAcitivtyLayer();
        } else {
          /**
           * We have passed color legend filtering, we can now engage SEZ layers.
           */
          //console.log('doLayering', 'map filtering requested');
          if(this.filter_selections.includes('sez')) { this.addSpecialEconomicZoneLayer() }
          if(this.filter_selections.includes('epz')) { this.addExportProcessingLayer() }
          if(this.filter_selections.includes('ftz')) { this.addFreeTradeZoneLayer() }
          if(this.filter_selections.includes('dz')) { this.addDiversifiedZoneLayer() }
          if(this.filter_selections.includes('rrz')) { this.addRegionalRevitalizationZoneLayer() }
          if(this.filter_selections.includes('cc')) { this.addCharterCityLayer() }
          if(this.filter_selections.includes('ForeignTrade')) { this.addForeignTradeLayer() }
          if(this.filter_selections.includes('OtherZone')) { this.addOtherTypeLayer() }
        }
      } else {
        /**
         * In comparison to the above case, this case needs only one layer.
         */
        //console.log('doLayering', 'map filtering not requested')
        // We need a way to know if the advanced search criteria need to be used
        let theQuery = null;
        if(this.isAdvancedSearchNeeded) {
          //console.log('doLayering', 'map advanced parameters requested')
          theQuery = await this.$store.dispatch('composeSQL');
        } else {
          theQuery = baseFields;
          //console.log('doLayering', 'map advanced parameters not requested', theQuery);  
        }
        //console.log('doLayering',theQuery);
        const source = await new cartovl.source.SQL(
          theQuery,{
            apiKey: process.env.VUE_APP_CARTO_API_KEY,
            user: process.env.VUE_APP_CARTO_USERNAME
          }
        )
        const params = {
          color: '#29D9C2',
          strokeColor: '#000',
          strokeWidth: '1',
          sezsPresent: true,
          dynamicSizing: this.uniformSEZsize
        }
        // Carto layers seem to be sticky and interfere with procedurally adding them when they should be removed.
        if(this.map.getLayer('populated_places')) {
         //console.log('space holder');
        } else {
          const layer = getMapLayer('populated_places', source, params, this.isMobile);
          layer.addTo(this.map);
          this.addInteractivity(layer);
          // This next lines hlps keep track oh knowing how many layers need to be loaded
          this.mapLayersNeeded = this.mapLayersNeeded + 1;
        }
        // add special layer that adds water mark on the bottom right portin of the screen with text 'Open Zone Map - Adrianople Group © 2021'

      }
      /**
       * Add additional layers if conditions are set in state.
       */
      if(this.show_selected.includes("Capitals")) {
        this.addCapitalsLayer();
      }
      if(this.show_selected.includes("Port")) {
        this.addPortsLayer();
      }
      if(this.show_selected.includes("Airports")) {
        this.addAirportsLayer();
      }
      if(this.countrySelectedOnMap) {
        this.addOceanLayer();
      }
      /**
       * Countries need to be able to be interacted with.
       * COMMNENTED OUT 5/19/2020
       */
      //this.addCountriesLayer()
      }
      foo();
      setTimeout(async() => {
        this.mapBusy = false;
      }, 500);
    },
    async initMap() {
      /**
       * Instantiate the base map
       */
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: `https://api.mapbox.com/styles/v1/cyberconcepts/ckss3i7qd2twh18o4ndcbip89?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`,
        center: [-5, 45],
        zoom: 2,
        attributionControl: false,
        dragPan: true,
        dragRotate: false
      })
      /**
       * Add map attribution
       */
      // this.map.addControl(new mapboxgl.AttributionControl({
      //   customAttribution: 'Open Zone Map - Adrianople Group © 2021',
      // }), 'bottom-right');
      /**
       * Add Midnight commander styling
       */
      this.map.on('load', () => {
        this.map.addSource('midnight', {
          type: 'vector',
          tiles: [`https://api.mapbox.com/styles/v1/cyberconcepts/ckss3i7qd2twh18o4ndcbip89.html?fresh=true&title=view&access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`]
        })
      });
      // this.map.on('render', () => {
      //   this.map.resize();
      // });
      /**
       * Add map navigation control
       */
      const nav = new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: true,
        visualizePitch: false
      });
      this.map.addControl(nav, 'top-left');
      // kick the can down the road
      //console.log('initMap -> doLayering()')
      this.doLayering()
    },
    setIsMobile() {
      // var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
      // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if(window.innerWidth / window.innerHeight < 1) {
        this.$store.dispatch('updateIsMobile', true);
      } else {
        this.$store.dispatch('updateIsMobile', false );
      }
    },
    catchResize() {
      this.$router.go(0);
    }
  },
  created() {
    this.setIsMobile();
    // let iHeight = window.innerHeight;
    // let iWidth = window.innerWidth;
   //console.log('CartoSEZMap.create()', iHeight, iWidth);
    this.setIsMobile();
  },
  async mounted() {
    this.initMap()
    // Add event listener at root for quick search
    this.$root.$on('mapQuickSearch', this.doLayering);
    // Add event listener 
    this.$root.$emit('map-navigation');
    // TODO: Debounce this
    this.calculateBottomCtlPosition();
    window.addEventListener('resize', this.calculateBottomCtlPosition);
    // DEPRICATED event but is the only event that may still be active in safari
    window.addEventListener("orientationchange", this.catchResize);

  },
  beforeDestroy() {
    // Clean up event listener
    this.$root.$off('mapQuickSearch', this.doLayering);

    window.removeEventListener('resize', this.calculateBottomCtlPosition);

    window.removeEventListener("orientationchange", this.catchResize);
  }
}
</script>

<style lang="scss">

  /* global color set */
  $purple: #B125DE;
  $blue: #29D9C2; 
  $green: #6DD400;
  $yellow: #FEE08B;
  $orange: #FF9666;
  $red: #ED0245;
  $electricBlue: #0a5fee;
  $gold: rgb(167, 135, 109);

  @font-face {
    font-family: 'EBGaramond-VariableFont_wght';
    src: url('../assets/fonts/EBGaramond-VariableFont_wght.ttf') format('truetype');
  }

  @font-face {
    font-family: 'EBGaramond-Italic-VariableFont_wght';
    src: url('../assets/fonts/EBGaramond-Italic-VariableFont_wght.ttf') format('truetype');
  }

  .adv-btn { 
    text-decoration: underline !important;
  }

  .search-input { 
    font-family: 'Avenir-Light' !important;
    font-size: 26px !important;
    border: 0 !important;
  }

  .mapbox-logo, .mapboxgl-ctrl-logo {
    display: none !important;
  }

  .btn-custom {
    margin-left: 1.95rem;
    background-color: rgba(0,0,0,0) !important;
    border-color: rgb(167, 135, 109) !important;
    border-radius: 0px !important;
    color: #fff !important;
  }
  .btn-custom:hover {
    background-color: #fff !important;
    color: rgb(22, 35, 52) !important;
  }

  // button, a, .btn { 
  //   font-family: 'Avenir-Regular' !important;
  //   font-size: 14px !important;
  //   color: #4A4A4A !important;
  //   text-align: justify !important;
  //   line-height: 20px !important;
  // }
  #mobile-map-content {
    position: fixed;
  }

  #map {
    width: auto;
    height: 80vh;
  }

  #app {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  @media screen and (max-width: 450px) {
    #map {
      width: 100vw;
      height: 90vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    @media screen and (min-height: 812px) {
      #map {
        width: 100vw;
        height: 93.5vh;
        height: calc(var(--vh, 1vh) * 93.5);
      }
    }
    @media screen and (min-height: 896px) {
      #map {
        width: 100vw;
        height: 95vh;
        height: calc(var(--vh, 1vh) * 95);
      }
    }
  }

  #mobile-map-navbar {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    z-index: 10;
    top: 0.5rem;
    a {
      font-family: 'EBGaramond-VariableFont_wght' !important;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      color: #fff;
    }
    > div { 
      > div { 
        a, p {
          display: inline;
        }
        p {
          font-size: 0.75rem;
          font-weight: 600;
          font-family: 'Roboto', sans-serif;
          color: rgb(167, 135, 109);
        }
        > svg {
          position: absolute;
          right: 3vw;
          color: rgb(79, 103, 130);
          width: 2.5rem;
          height: 2.5rem;
          border-style: solid;
          border-width: 1px;
          border-color: rgb(79, 103, 130);
          border-radius: 30px;
          padding: 7.5px;
        } 
      } 
    }
    // container fix for landscape
  }
  // @media (max-width: 926px) {
  //   #mobile-map-navbar.container {
  //     max-width: 926px !important;
  //   }
  // }
  // @media (max-width: 895px) {
  //   #mobile-map-navbar.container {
  //     max-width: 895px !important;
  //   }
  // }
  // @media (max-width: 844px) {
  //   #mobile-map-navbar.container {
  //     max-width: 844px !important;
  //   }
  // }
  // @media (max-width: 812px) {
  //   #mobile-map-navbar.container {
  //     max-width: 812px !important;
  //   }
  // }
  // @media (max-width: 736px) {
  //   #mobile-map-navbar.container {
  //     max-width: 736px !important;
  //   }
  // }
  // @media (max-width: 667px) {
  //   #mobile-map-navbar.container {
  //     max-width: 667px !important;
  //   }
  // }
  // @media (max-width: 568px) {
  //   #mobile-map-navbar.container {
  //     max-width: 568px !important;
  //   }
  // }

  #right-filter-ctl {
    position: absolute;
    top: 30.25%;
    transition: transform 1s;

    .outer-half-circle {
      position: relative;
      border-width: 2px;
        border-style: solid;
        border-color: rgb(255, 255, 255);
        border-top-left-radius: 4rem;
        border-bottom-left-radius: 4rem;
        background-color: #C8CBD0;
        height: 8rem;
        width: 4rem;

      .inner-half-circle {
        position: relative;
        margin-top: 0.425rem;
        margin-left: 0.35rem;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(255, 255, 255);
        border-top-left-radius: 3.5rem;
        border-bottom-left-radius: 3.5rem;
        background-color: #E1E3E6;
        height: 7rem;
        width: 3.5rem;

      }

      .inner-ctl {
        position: absolute;
        top: 40%;
        left: -20%;
        border-style: solid;
        border-width: 2px;
        border-radius: 1.5rem;
        border-color: rgb(255, 255, 255);
        width: 2.25rem;
        height: 2.25rem;
        background: linear-gradient(#C8CBD0, #E1E3E6, #ffffff);
        svg {
          position: absolute;
          left: 15%;
          top: 15%;
          width: 1.25rem;
          height: 1.25rem;
          color: #536C89;
          transform: rotate(180deg);
        }
      }
    }
    
    .mobile-legend-wrapper {
      width: 16rem;
    }
    .mobile-legend-wrapper, .outer-half-circle {
      float: left;
    }
  }
  // Mobile screens need custom expansion widths
  @media screen and (min-width: 320px) {
    #right-filter-ctl {
      right: -16rem;
    }
    .right-filter-expanded {
      transform: translate(-15rem, 0%);
    }
  }

  @media screen and (min-width: 375px) {
    #right-filter-ctl {
      right: -16rem;
    }
    .right-filter-expanded {
      transform: translate(-15.5rem, 0%);
    }
  }
  #bottom-ctl-screenout {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    visibility: hidden;
  }
  #bottom-filter-ctl {
    position: relative;
    background-color: rgba(255, 255, 255, 0.95);
    //bottom: 0rem; // lefto ver from when this was absolutley positioned
    height: 5vh; // This is to take up the 5vh left over from the map
    transition: transform 1s;
    .bottom-ctl-toggle {
      margin-top: 0.4rem;
      border-style: solid;
      border-color: rgb(167, 135, 109);
      border-radius: 3px;
      cursor: pointer;
    }
    #bottom-ctl-pane {
      background-color: rgb(255, 255, 255);
      color: rgb(0, 0, 0);
      label {
        color: $gold;
        letter-spacing: 3px;
        font-size: 0.8rem;
      }
    }
  }

  .bottom-ctl-raised {
    transform: translate(0rem, -60vh);
  }

  // #clear-ctl-button {
  //   position: absolute;
  //   top: -25rem;
  //   left: 0rem;
  // }

  // #play-demo-button {
  //   position: absolute;
  //   top: -7rem;
  //   right: 3rem;
  //   cursor: pointer;
  //   p {
  //     color: #8C8C8C;
  //   }
  // }

  // #demo-button {
  //   color: #8C8C8C;
  //   height: 3rem;
  //   width: 3rem;
  // }

  .list-group {
    border-radius: 0px !important;
  }

  .list-group, .list-group-horzontal, .list-group-item {
    background-color: rgba(7, 16, 26, 1) !important;
  }

  .list-group-item {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    p {
      display: inline-block;
      font-size: 0.75rem;
      margin-bottom: 0rem;
      padding-left: 0.2rem;
    } 
  }

  .legendRect {
    display: inline-block;
    padding-bottom: 4px;
    width: 0.75rem;
    height: 0.75rem;
  }

  #legendSEZ, #legendPrivate {
    background-color: $purple;
  }

  #legendDZ, #legendPublic-Private {
    background-color: $blue;
  }

  #legendActive {
    background-color: $green;
  }

  #legendInactive {
    background-color: $red;
  }

  #legendUnderDevelopment {
    background-color: $yellow;
  }

  #legendFTZ {
    background-color: $green;
  }

  #legendEPZ {
    background-color: $orange;
  }

  #legendRRZ, #legendPublic {
    background-color: $yellow;
  }

  #legendCC {
    background-color: $red;
  }

  #legendForeign {
    background-color: $electricBlue;
  }

  #legendOther {
    background-color: #fff;
    border-style: solid;
    border-color: $red;
    border-width: 1px;
  }

  .form-link {
    padding: 0.375rem 0.75rem;
  }

  .custom-spacer-lighter {
    height: 2rem;
  }

  .custom-spacer {
    background-color: rgba(7, 16, 26, 1);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  #map-wrapper {
    background-color: rgb(22, 35, 52);
  }

  @media (min-width: 1152px) {
    .modal-dialog {
      max-width: 1000px !important;
      margin: 1.75rem auto !important;
    }
  }

  @media (min-width: 1024px) {
    .modal-dialog {
      max-width: 850px !important;
      margin: 1.75rem auto !important;
    }
  }

  // Desktop modal CSS
  .modal-content {
    //background: rgb(22, 35, 51);
    border: 2px solid rgb(167, 135, 109) !important;
    border-radius: unset !important; 
    //color: rgb(22, 35, 51);
    width: 100%;
    
    .modal-header {
      border-bottom: none;
      display: none; 
    }
    .container-fluid {
      border: 2px solid rgb(167, 135, 109) !important;
      padding: unset;
      padding-bottom: 3rem;
      .row {
        padding-left: 1.5rem;
        .modal-exit {
          padding-left: 0.6rem;
        }
      }
      .zone-info {
        padding-left: 2.5rem;
      }
      svg {
        padding-right: 1rem;
        margin-right: 1.75rem;
        margin-top: 0.75rem;
        transform: scale(2);
        color: rgb(255, 255, 255);
      }
      svg:hover {
        cursor: pointer;
      }
    }
    .zone-image {
      width: 25%;
      max-width: 25%;
      
    }
    .title {
      padding-top: 0.5rem;
      margin-left: unset;
      margin-right: unset;
      padding-bottom: 0.5rem;
      background-color: #1c3556;
      color: rgb(255, 255, 255);
      .zone-title h1{
        font-size: 48px !important;
      }
      .zone-title label {
        font-size: 20px;
      }
    }
    .seperator {
      margin-right: 1rem;
    }
    .zone-seperator {
      background-color: rgb(167, 135, 109);
      height: 2px;
      margin-right: unset;
    }
    .zone-info {
      padding-top: 1rem;
      margin-bottom: 1rem;
      div {
        label:first-child {
          font-size: 18px;
          color: rgb(167, 135, 109);
        }
        label:nth-child(2n) {
          font-size: 24px;
          font-family: 'EBGaramond-VariableFont_wght';
          display: block;
        }
      }
    }
    .read-more {
      justify-content: center;
      position: absolute;
      padding-left: unset;
      left: 18rem;
      bottom: -2.3rem;
      .btn-read-more {
        border-radius: unset;
        background-color: rgb(83, 108, 135);
        font-size: 30px;
        font-family: 'EBGaramond-VariableFont_wght';
        padding-left: 3rem;
        padding-right: 3rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }.btn-read-more:hover {
        background-color: rgb(167, 135, 109);
        border-color: rgb(167, 135, 109);
      }
    }
  }
  // Mobile modal CSS
  .modal-dialog {
    .modal-content {
      .modal-body {
        #mobile-modal-content {
          .outer-square {
            .inner-square {
              border: 1px solid rgb(167, 135, 109);
              padding-bottom: 1.5rem;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              padding-top: 0.75rem;
              .mobile-modal-intro {
                font-family: 'Roboto';
                letter-spacing: 2px;
                text-transform: uppercase;
                .mobile-modal-exit {
                  svg {
                    position: absolute;
                    left: 0;
                    top: 10%;
                    width: 2rem;
                    height: 2rem;
                    color: rgb(167, 135, 109);
                  }
                }
              }
              .row:first-child{
                display: flex;
                justify-content: space-between;
              }
              .row {
                padding-bottom: 1.25rem;
                
                .mobile-modal-zonename {
                  h1 {
                    color: #000;
                    padding-bottom: 1.5rem;
                    border-bottom: 1px solid rgb(167, 135, 109);
                  }
                }
                .col {
                  label {
                    display: block;
                    margin-bottom: unset;
                  }
                  label:first-child {
                    color: rgb(167, 135, 109);
                    letter-spacing: 2px;
                  }
                  label:nth-child(2) {
                    font-family: 'EBGaramond-VariableFont_wght';
                    font-size: 1.5rem;
                    font-weight: 100;
                  }
                }
              }
              .row:last-child {
                padding-bottom: unset;
              }
              .mobile-read-more {
                .btn-read-more {
                  border-radius: unset;
                  background-color: rgb(83, 108, 135);
                  font-size: 30px;
                  font-family: 'EBGaramond-VariableFont_wght';
                  padding-left: 3rem;
                  padding-right: 3rem;
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                }.btn-read-more:hover {
                  background-color: rgb(167, 135, 109);
                  border-color: rgb(167, 135, 109);
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 414px) {
    .modal-dialog {
      width: 80vw !important;
      margin-left: 10% !important;
      margin-right: 10% !important;
      margin-top: 15% !important;
      display: block;
      .modal-content {
        .modal-body {
          #mobile-modal-content {
            .outer-square {
              .inner-square {
                .mobile-read-more {
                  .btn-read-more {
                  }.btn-read-more:hover {
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #meta-map-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;
  }.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /** Customization for checkbox styling (uncheked) */
  .custom-checkbox > [type="checkbox"]:not(:checked) + label:before {
    background-color: rgb(34, 62, 80) !important;
    border-radius: unset !important;
  }
  /** Customization for checkbox styling (cheked)*/
  .custom-checkbox > [type="checkbox"]:checked + label:before {
    background-color: rgb(167, 135, 109) !important;
  }
  /** Customization for checkbox styling (cheked)*/
  .custom-radio > [type="radio"]:not(:checked) + label:before {
    background-color: rgb(34, 62, 80) !important;
  }
  /** Customization for checkbox styling (cheked)*/
  .custom-radio > [type="radio"]:checked + label:before {
    background-color: rgb(167, 135, 109) !important;
  }

  div.mapboxgl-ctrl-top-left {
    margin-top: 8vh;
  }

  #clear-ctl-button {
    div {
      button:hover {
        background: rgb(167, 135, 109);
        border-color: rgb(167, 135, 109);
        color: rgb(255, 255, 255);
      }
    }
  }

  #mobile-clear-ctl-button {
    position: absolute;
    top: 20%;
    left: 0.90rem;
    border-radius: 15px;
    width: 1.5rem;
    height: 1.5rem;
    p {
      position: absolute;
      font-size: 0.75rem;
      left: -0.4rem;
      color: rgb(214, 173, 95);
    }
  }

  #mobile-clear-ctl-button:hover {
    transform: scale(1);
    animation: pulse 2s infinite;

    display: inline-block;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgb(255, 255, 255);
    } 70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.5rem rgba(41, 217, 194, 0);
    } 100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(41, 217, 194, 0);
    }
  }
</style>