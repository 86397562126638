<template>
  <div id="menu">
    <div class="mobile-menu" v-if="isMobile">
      <div class="top-half">
        <BIconXCircle v-on:click = "navBack" class="menu-exit"/>
        <b-row>
          <b-col>
            <router-link to="/">
              <h3>Home</h3>
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <a href="http://Adrianoplegroup.com/zonemap/about">
              <h3>About</h3>
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <a href="http://Adrianoplegroup.com/zonemap/how-to">
              <h3>How To</h3>
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <a href="http://Adrianoplegroup.com/blog">
              <h3>Blog</h3>
            </a>
            <!-- <router-link>
              <h3>Blog</h3>
            </router-link> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <router-link to="/Advanced">
              <h3>
                Advanced
              </h3>
            </router-link>
          </b-col>
        </b-row>
      </div>
      <div class="bottom-half">
        <div>
          <h3>Open Zone Map</h3>
          <p>BETA</p>
        </div>
        <h4 class="dourado">Mapping the World's Special Economic Zones</h4>
        <p>Are you an academic, government or non-profit and want to study our raw data? Reach out to us via <span class="dourado"> <a href="mailto:study@openzonemap.com">study@openzonemap.com</a> </span></p>
        <h5>Created by <a href="https://www.adrianoplegroup.com/">Adrianople Group</a> | Designed by <a href="https://www.ap-strategies.com/">Atlantic Pacific Strategies</a></h5>
        <h5> Developed by <a href="">Cyber Concepts LLC</a></h5>
        <p class="bug-report"> Do you have feedback? If you find a bug, would like to add a correction to the data, or have any other comments please submit them <a href="http://Adrianoplegroup.com/zonemap/report-bug">here</a>.</p>
        <h5> Copyright 2021, Creative Commons with Attribuition</h5>
      </div>
    </div>
    <div v-else class="menu">
      <b-row>
        <BIconXCircle v-on:click = "navBack" class="menu-exit"/>
        <b-col align-self="center">
          <router-link to="/">
            <h3
                @mouseover="hoverHome = true"
                @mouseleave="hoverHome = false"
                v-on:click = "navBack"
             >Home</h3>
          </router-link>
          <hr v-bind:class="{hrHover: hoverHome}">
          <a href="http://Adrianoplegroup.com/zonemap/about">
            <h3
              @mouseover="hoverAbout = true"
              @mouseleave="hoverAbout = false"
            >About</h3>
          </a>
          <hr v-bind:class="{hrHover: hoverAbout}">
          <a href="http://Adrianoplegroup.com/zonemap/how-to">
            <h3 
              @mouseover="hoverTour = true"
              @mouseleave="hoverTour = false"
            >How To</h3>
          </a>
          <hr v-bind:class="{hrHover: hoverTour}">
          <a href="http://Adrianoplegroup.com/blog">
              <h3
                @mouseover="hoverBlog = true"
                @mouseleave="hoverBlog = false"
              >Blog</h3>
            </a>
          <hr v-bind:class="{hrHover: hoverBlog}">
          <router-link to="/Advanced">
            <h3
              v-on:click = "navBack"
            >Advanced</h3>
          </router-link>
        </b-col>
        <div class="col-lg-6" id="content">
          <h3>Open Zone Map</h3>
          <h4 class="dourado">Mapping the World's Special Economic Zones</h4>
          <img :src="cityScape">
          <p>Are you an academic, government or non-profit and want to study our raw data? 
            <br> Reach out to us via 
            <span class="dourado"> 
              <a href="mailto:study@openzonemap.com">study@openzonemap.com</a> 
            </span>
          </p>
          <h5>Created by <a href="https://www.adrianoplegroup.com/">Adrianople Group</a> | Designed by <a href="https://www.ap-strategies.com/">Atlantic Pacific Strategies</a></h5>
          <h5>Developed by <a href="">Cyber Concepts LLC</a></h5>
          <p class="bug-report"> Do you have feedback? If you find a bug, would like to add a correction to the data, or have any other comments please submit them <a href="http://Adrianoplegroup.com/zonemap/report-bug">here</a>.</p>
          <h5>Copyright 2021, Creative Commons with Attribuition</h5>
        </div>
      </b-row>
    </div>
</div>
</template>
<script>
import cityScape from '../assets/cityscape.png';
import {mapState} from 'vuex';
export default {
  computed: {
    ...mapState([
      'isMobile'
    ])
  },
  data() {
    return {
      hoverHome : false,
      hoverAbout : false,
      hoverTour : false,
      hoverBlog : false,
      cityScape
    }
  },
  methods: {
    navBack() {
      var element = document.querySelector("#menu");
      var op = 1.0; // initial opacitity
      let timer = setInterval(() => {
        if(op <= 0.0) { 
          element.style.visibility = "hidden";
          clearInterval(timer);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op = op - 0.05;
      }, 10); 
      if(this.$route.name === "Map" && this.isMobile) {
        // make map disappear
        var mapElement = document.querySelector("#map-wrapper");
        mapElement.style.visibility = "visible";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  div.menu {
    div.row {
      position: relative;
      svg.b-icon {
        position: absolute;
        color: #546c8a;
        top: 3vh;
        right: 3vw;
        z-index: 1;
        //transform: scale(2);
        cursor: pointer;
        width: 2.5rem;
        height: 2.5rem;
      }
      div.col {
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}
#menu {
  opacity: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index:  10000;
  visibility: hidden;
  padding-left: 10vh;
  text-align: left;
  //padding-top: 90vh;
  background-color: rgb(22, 35, 52);
  background-image: linear-gradient(rgb(22, 35, 52), #203755);
  .menu {

    padding-top: 90vh;
    .row {
      div.col.align-self-center {
        a {
          color: inherit;
        }
        hr {
          box-shadow: 0px 0px 1px #546c8a80;
          padding-bottom: 1px;
          width:80%; 
          margin-top: 0px;
          background-color:#546c8a;
          height: 0.02  vh;
          position: absolute;
        }
        hr:hover {
          box-shadow: #ac8a6cb7; 
          background-color: #AC8A6C;
        }
        h3 {
          line-height: 15vh;
          font-family: 'Cormorant Garamond', serif !important;
          font-weight: 500;
          font-size: 80px !important;
        }
      }
      div#content.col-lg-6 {
        height: 100vh !important;
        padding-top: 5vh;
        padding-right: 5vw;
        padding-left: 5vw;
        text-align: left;
        background-color:#fff;
        color: rgb(22, 35, 52);
        img {
          padding-top: 3vh;
          padding-bottom: 3vh;
          width: 100%;
        }
        .dourado {
          color: #AC8A6C;
        }
        p {
          font-family: 'Cormorant Garamond', serif !important;
          font-size: 30px;
          line-height: 5vh;
          span.dourado {
            a {
              text-decoration: none;
              color: #AC8A6C;
            }
          }
        }
        p.bug-report {
          color: #a2a2a2;
          margin-top: 1rem;
          margin-bottom: 2rem;
          font-size: 20px;
          line-height: 20px;
          a {
            color: #AC8A6C;
          }
        }
        h4 {
          margin-top: 1rem;
          line-height: 15px;
          font-style:italic;
          font-weight: 500;
          font-family: EB Garamond, serif !important;
          font-size: 24px !important;
        }
        h5 {
          line-height: 15px;
          color: #a2a2a2;
          font-weight: 500;
          font-size: 20px !important;
          font-family: 'Cormorant Garamond', serif !important;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }
  }
  .mobile-menu {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    div.top-half {
      height: 50%;
      padding-top: 1.5rem;
      padding-left: 1rem;
      padding-bottom: 1rem;
      background: linear-gradient(#0B1A2E, #233A5A);
      .row {
        .col {
          a {
            color: unset;
            text-decoration: none;
          }
          h3 {
            font-size: 3rem !important;
          }
        }
      }
      .menu-exit {
        position: absolute;
        color: #546c8a;
        top: 3vh;
        right: 3vw;
        z-index: 1;
        //transform: scale(2);
        cursor: pointer;
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    div.bottom-half {
      padding-top: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%;
      height: 50%;
      background-color: #ffffff;
      div {
          h3, p {
            display: inline;
          }
          h3 {
            color: #000;
            font-size: 2.5rem !important;
          }
          p {
            border-bottom: unset;
            color: #AC8A6C;
            padding-left: 0.25rem;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
          }
        }
      h4.dourado {
        color: #AC8A6C;
        //line-height: 15px;
        font-style: italic;
        font-weight: 500;
        font-family: EB Garamond, serif !important;
        font-size: 1.25rem !important;
      }
      p {
        color: #000;
        font-family: 'Cormorant Garamont, Bold';
        font-size: 1rem;
        font-weight: 500;
        display: block;
        span.dourado {
          a {
            font-family: 'Cormorant Garamont, Bold' !important;
            font-size: 1rem;
            text-decoration: none;
            color: #AC8A6C;
          }
        }
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #A2A2A2;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }
      p.bug-report {
        color: #a2a2a2;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-bottom: unset;
        font-size: 1rem;
        line-height: 1.25rem;
        border-bottom: unset;
        a {
          font-family: 'Cormorant Garamont, Bold' !important;
          font-size: 1rem;
          color: #AC8A6C;
        }
      }
      h5 {
        color: #A2A2A2;
        font-family: 'Cormorant Garamont, Bold' !important;
        font-size: 1rem;
        display: inline;
        line-height: 1rem;
        a {
          font-size: 1rem;
          font-family: 'Cormorant Garamont, Bold' !important;
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  #menu {
    // .mobile-menu {
    //   overflow-y: scroll;
    // }
    .mobile-menu { 
      div.top-half { 
        height: 40%;
        .row { 
          .col { 
            h3 {
              font-size: 1.75rem !important;
            }
          }
        }
      }
    }
    .mobile-menu { 
      div.bottom-half { 
        height: 60%;
        padding-top: 0.75rem;
        div { 
          h3 {
            font-size: 1.75rem !important;
          }
        }
        p {
          font-size: 0.8rem;
          span.dourado {
            a {
              font-size: 0.8rem;
            }
          }
        }
        h5 {
          font-size: 0.8rem;
          line-height: 1rem;
          a {
            font-size: 0.8rem;
            line-height: 0.8rem;
          }
        }
        p.bug-report {
          font-size: 0.8rem;
          line-height: 1rem;
          a {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  #menu {
    // .mobile-menu {
    //   overflow-y: scroll;
    // }
    .mobile-menu { 
      div.top-half { 
        height: 50%;
        .row { 
          .col { 
            h3 {
              font-size: 2.5rem !important;
            }
          }
        }
      }
    }
    .mobile-menu { 
      div.bottom-half { 
        height: 50%;
        padding-top: 0.75rem;
        div { 
          h3 {
            font-size: 2.5rem !important;
          }
        }
        p {
          font-size: 0.9rem;
          span.dourado {
            a {
              font-size: 0.9rem;
            }
          }
        }
        h5 {
          font-size: 0.9rem;
          line-height: 0.9rem;
          a {
            font-size: 0.9rem;
            line-height: 0.9rem;
          }
        }
        p.bug-report {
          font-size: 0.9rem;
          line-height: 0.9rem;
          a {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
</style>
