<template>
  <div id="mobile-legend-ctl">
    <label>LEGEND COLORS</label>
    <div>
      <div
        v-for="(item, key) in color_options"
        :key="key"
      >
        <input 
          type="radio"
          :id="item.value" 
          :value="item.value" 
          v-model="picked" 
          v-on:click="change_legend_option(item.value)" 
        />
        <label>{{item.text}}</label>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed : {
    ...mapState([
      'colorLegendType'
    ])
  },
  data() {
    return {
      color_options: [
        { text: 'COLOR BY ZONE TYPE', value: 'zone_type', checked: false},
        { text: 'COLOR BY ZONE MANAGEMENT TYPE', value: 'management_type', checked: false},
        { text: 'COLOR BY ZONE ACTIVITY STATUS', value: 'zone_activity', checked: false},
      ],
      picked: null
    }
  },
  methods : {
    change_legend_option(e) {
     //console.log('change_legend_option', e, this.colorLegendType);
      if(this.colorLegendType == e) {
        this.$store.dispatch('updateColorFilterSelection');
        this.picked = null;
      } else {
        this.$store.dispatch('updateColorFilterSelection', e);
      }
      setTimeout(() => {
        // Give it some time man
        this.$emit("update-filters", 'color_filter_selected');
      }, 500)
    }
  },
  mounted() {
    this.picked = this.colorLegendType;
  }
}
</script>
<style lang="scss">
#mobile-legend-ctl {
  border-bottom: 1px solid rgb(167, 135, 109);
  padding-bottom: 0.5rem;
  div {
    display: inline-block;
    div {
      position: relative;
      margin-left: 0.5rem;
      margin-top: -10px;
      //display: inline;
      label {
        font-size: 8px !important;
        letter-spacing: 0.5px !important;
        //width: 29vw;
        height: 2vh;
        color: #000000 !important;
        padding-left: 0.25rem;
        margin-bottom: unset;
        justify-content: right;
        padding-left: 0.5rem;
      }
      input {
        //position: absolute;
        line-height: 1rem;
        padding-bottom: 3rem;
        top: 0;
      }
    }
  }
}
#mobile-legend-ctl > label {
  margin-bottom: unset;
  margin-left: 0.5rem;
}
</style>