<template>
  <div class="criteria-selection-ui">
    <div
      class="criteria-option"
      v-for="(item, key) in choices"
      :key="key"
    >
      <!-- <b-button
        @mouseover="e => e.target.firstElementChild.className = 'slide-right'"
        @mouseleave="e => {
          e.target.firstElementChild.className = 'slide-left'
          //slideAway(e.target.firstElementChild);
        }"
        variant="link"
        @click="selection({ item, parameterString })"
        class="shadow-none"
      >{{item}}</b-button> -->
      <b-button
        variant="link"
        @click="selection({ item, parameterString })"
        class="shadow-none"
      >{{item}}</b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    criteria: {
      type: String, 
      required: false
    },
    choices: {
      type: Array,
      required: true
    },
    selection: {
      type: Function,
      required: true
    },
    clear: {
      type: Function,
      required: false
    },
    parameterString: {
      type: String,
      required: true
    }
  },
  methods:{
    
  }
  ,
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.criteria-selection-ui {
  // overflow-x: hidden;
  // overflow-y: auto;
  // height: 80vh;
  .criteria-option {
    button {
      color: #000;
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
}
@media (max-width: 425px) {
  .criteria-selection-ui {
    .criteria-option {
      button {
        text-align: left;
      }
    }
  }
}
.static{
  visibility: hidden;
}
.slide-right {
  visibility: visible;
  background-color: black;
  height: 1px;
	-webkit-animation: slide-right 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: slide-right 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
.slide-left {
  background-color: black;
  height: 1px;
	-webkit-animation: slide-left 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: slide-left 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

</style>