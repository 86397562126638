import cartovl from '@carto/carto-vl';

/**
 * This function will incorporate the source data and some configuration propoerties and return a layer to add to a mapbox map.
 * @param {the name of the layer that will be used in a mapbox map} layerName 
 * @param {the data that will displayed onto th map} source 
 * @param {configuration for visulaization expressions} params 
 */
function getMapLayer(layerName, source, params, isMobile = false) {
    let vizExpression = null
    try {
        vizExpression = composeVizExpression(params, isMobile)
    } catch (err) {
       //console.log(err)
    }
    //console.log(`visulaization expression, ${vizExpression}`)
    const viz = new cartovl.Viz(vizExpression)
    const layer = new cartovl.Layer(layerName, source, viz)
    return layer
}

export { getMapLayer }

function composeVizExpression(params, isMobile) {
    let expression = ''
    if(params.color) {
        expression += `color: ${params.color}\n`
    }
    if(params.strokeColor) {
        expression += `strokeColor: ${params.strokeColor}\n`
    }
    if(params.strokeWidth) {
        expression += `strokeWidth: (${params.strokeWidth} / 10)\n`
    }
    if(params.dynamicSizing) {
        if (isMobile) {
            expression += 'width: ramp(zoomrange([5, 6.5]), [($size_class * 1.5), ($size_class * 10.0)])\n'
        } else {
            expression += 'width: ramp(zoomrange([4, 6]), [($size_class * 1.5), ($size_class * 10.0)])\n'
        }
    } else {
        if (isMobile) {
            expression += 'width: ramp(zoomrange([4, 6]), [14, 60])\n'
        } else {
            expression += 'width: ramp(zoomrange([5, 6.5]), [10, 20])\n'
        }
    }
    if(params.sezsPresent) {
        expression += '@sezs: viewportFeatures($zone_name, $size_hectares, $zone_type, $zone_specialization, $country, $management_company, $latitude, $longitude, $management_type)\n'
    }
    return expression
}

function stringFixer(input) {
  if (typeof input === "string")
  {
    return String(input).replace('_x000D_', '');
  } else {
    return input;
  }
}

export { stringFixer };