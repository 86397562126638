<template>
  <div id="search-mobile-ctl">
    <div class="exit-container">
      <BIconXCircle v-on:click="hideSearch"/>
    </div>
    <b-row>
      <b-form id="quicksarch-mobile" @submit="quickSearch">
        <b-input-group size="lg">
          <b-form-input
            @input="updateQuickSearch"
            :value="quickSearchString"
            :placeholder="placeholder"
            class="search-input"
          />
          <b-input-group-append>
            <!-- <label v-if="getPath() == 'Map'"
              @click="quickSearchMap()">
              <b-icon-search/>
            </label>
            <label v-else
              @click="quickSearch()">
              <b-icon-search/>
            </label> -->
            <label>
              <b-icon-search/>
            </label>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-row>
    <b-row>
      <div class="btn-wrapper">
        <b-button
          @click="quickSearchMap()"
        >
          SEARCH
        </b-button>
      </div>
    </b-row>
    <b-row>
      <div class="btn-wrapper">
        <b-button
          to="/Advanced"
        >
          ADVANCED SEARCH
        </b-button>
      </div>
    </b-row>
  </div>  
</template>
<script>
import {
  BIconSearch,
  BIconXCircle
} from 'bootstrap-vue';
import {mapState} from 'vuex';
export default {
  name: 'SEZ-quick-search',
  props: {},
  components: {
    BIconSearch,
    BIconXCircle
  },
  computed: {
    ...mapState([
      'quickSearchString'
    ]),
    placeholder: {
      get() {
        var placeholder = this.$store.state.quickSearchString;
        if (placeholder) {
          return placeholder;
        } else {
          return 'Search for a zone';
        }
      }
    }
  },
  data () {
    return {

    };
  },
  methods: {
    quickSearch() {
     //console.log('quickSearch()');
    },
    hideSearch() {
     //console.log('hideSearch()');
      var el = document.querySelector("#search-mobile-ctl");
      var opacity = el.style.opacity;
      var timer = setInterval(() => {
        if (opacity <= 0) {
          el.style.visibility = "hidden";
          clearInterval(timer);
        }
        el.style.opacity = opacity;
        el.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
          opacity -= 0.05;
      }, 10);
    },
    quickSearchMap() {
      /**
       * We need to tell the map component to fire it's quicksearch() method
       */
      this.$root.$emit('mapQuickSearch');
      this.hideSearch();
    },
    updateQuickSearch(e) {
      //this.$store.commit('setQuickSearchString', e);
      this.$store.dispatch('updateKeywordSearchString', e);
    },
  },
  async mounted() {
    //console.log('QuickSearch Event: mounted::', this.getPath())
  }
}
</script>
<style scoped lang="scss">
#search-mobile-ctl {
  z-index: 100;
  opacity: 0;
  position: absolute;
  left: 0px;
  top: -0.5rem;
  visibility: hidden;
  background-color: rgba(22, 35, 52, 0.9);
  width: 100vw;
  height: 100vh;
  .exit-container {
    display: flex;
    flex-direction: row-reverse;
    svg {
      position: absolute;
      top: 3vh;
      right: 3vw;
      border: unset;
      height: 2.5rem;
      width: 2.5rem;
      // padding-right: 15px;
    }
  }
  .row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #quicksarch-mobile {
    display: block;
    width: 80%;
    margin-top: 35vh;
    margin-right: auto;
    margin-left: auto;
    .input-group {
      border: 1px solid;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-color: rgb(255, 255, 255);
      .search-input, .search-input:-internal-autofill-selected {
        padding: 0px;
        background-color: rgba(0, 0, 0, 0) !important;
      }
      .search-input::placeholder {
        color: rgb(255, 255, 255);
        font-family: 'EBGaramond-VariableFont_wght' !important;
        font-size: 30px;
      }
      .search-input:focus {
        color: rgba(177.5, 177.5, 177.5, 0.9);
        box-shadow: none !important;
      }
      label {
        //box-shadow: none !important;
        display: flex;
        justify-content: center;
        margin-bottom: unset;
        svg {
          color: rgb(255, 255, 255);
          width: 2.5rem;
          height: auto;
          border: unset;
        }
      }
      label:hover, label:focus {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
      }
    }
  }
  .row:nth-child(3), 
  .row:nth-child(4) {
    display: block;
    margin-right: auto;
    margin-left: auto;
    .btn-secondary {
      background-color: rgba(0,0,0,0) !important;
      border-color: rgb(167, 135, 109) !important;
      border-radius: 0px !important;
      color: #fff !important;
      width: 60vw;
      font-size: 21px;
      letter-spacing: 2px;
      font-family: 'Roboto' !important;
    }
    .btn-secondary:hover {
      background-color: #fff !important;
      color: rgb(22, 35, 52) !important;
    }
  }
}
</style>