<template>
  <div id="tags-container">
    <h1 class="text-left">Active Tags</h1>
    <b-row v-if="advancedSearch.region">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('region')"
      >
        {{advancedSearch.region}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="advancedSearch.zone_type">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('zone_type')"
      >
        {{advancedSearch.zone_type}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="advancedSearch.zone_specialization">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('zone_specialization')"
      >
        {{advancedSearch.zone_specialization}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="advancedSearch.management_type">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('management_type')"
      >
        {{advancedSearch.management_type}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="advancedSearch.activity">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('activity')"
      >
        {{advancedSearch.activity}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="isSizeHectares">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('size_hectares')"  
      >
        {{advancedSearch.size_hectares}}
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="advancedSearch.size_null">
      <b-button 
        class="custom-tag"
        @click="removeCriteria('size_null')"  
      >
        SEZ's of unknown size included
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="portActive">
      <b-button 
        class="custom-tag"
        @click="removePort()"  
      >
        port {{ portDistance.min }}km - {{ portDistance.max }}km
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="airportActive">
      <b-button 
        class="custom-tag"
        @click="removeAirport()"  
      >
        airport {{ airportDistance.min }}km - {{ airportDistance.max }}km
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="capitalActive">
      <b-button 
        class="custom-tag"
        @click="removeCapital()"  
      >
        capital city {{ capitalDistance.min }}km - {{ capitalDistance.max }}km
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="populousActive">
      <b-button 
        class="custom-tag"
        @click="removePopulous()"  
      >
        populous city {{ populousDistance.min }}km - {{ populousDistance.max }}km
        <BIconX />
      </b-button>
    </b-row>

    <!-- <b-row v-if="portActive">
      <b-button
        class="custom-tag"
        @click="removePort()"
      >
        port distance
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="airportActive">
      <b-button
        class="custom-tag"
        @click="removeAirport()"
      >
        airport distance
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="capitalActive">
      <b-button
        class="custom-tag"
        @click="removeCapital()"
      >
        capital distance
        <BIconX />
      </b-button>
    </b-row>
    <b-row v-if="populousActive">
      <b-button
        class="custom-tag"
        @click="removePopulous()"
      >
        populous distance
        <BIconX />
      </b-button>
    </b-row> -->

    <b-button v-if="isAdvancedSearchNeeded"
      class="search-button"
      @click="goToMap()"
    >
      Search
    </b-button>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { BIconX } from 'bootstrap-vue'
//import isEqual from 'lodash/isEqual';

export default {
  computed: {
    ...mapState([
      'advancedSearch',
      'portActive',
      'portDistance',
      'airportActive',
      'airportDistance',
      'capitalActive',
      'capitalDistance',
      'populousActive',
      'populousDistance'
    ]),
    ...mapGetters([
      'isAdvancedSearchNeeded'
    ]),
    isSizeHectares() {
      if (
        this.advancedSearch.size_hectares[0] === null 
        && this.advancedSearch.size_hectares[1] === null) {
        return false;
      } else {
        return true;
      }
    }
  },
  components: {
    BIconX
  },
  data () {
    return {

    }
  },
  methods: {
    removeCriteria(e) {
      this.$store.commit('nullifyAdvancedSearchParameter', e);
    },
    goToMap() {
      this.$router.push('/map');
    },
    removeDistance(e) {
      this.$store.commit('nullifyInfrastructureSearchParameters', e);
    },
    removePort() {
      this.$store.commit('unsetPortDistance');
    },
    removeAirport() {
      this.$store.commit('unsetAirportDistance');
    },
    removeCapital() {
      this.$store.commit('unsetCapitalDistance');
    },
    removePopulous() {
      this.$store.commit('unsetPopulousDistance');
    }
  }
}
</script>
<style lang="scss" scoped>
  #tags-container {
    margin-top: 3rem;
    margin-left: 3rem;
    z-index: 10;
    height: 100vh;
    position: relative;
    h1{
      color: rgb(0, 0, 0);
      font-family: 'EBGaramond-VariableFont_wght' !important;
      font-size: 36px !important;
      font-weight: 500 !important;
    }
    .row {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      button {
        color: rgb(79, 103, 130);
        border-color: rgb(79, 103, 130);
        background-color: rgba(0, 0, 0, 0);
        border-radius: unset;
      }
    }
    .search-button {
      position: absolute;
      display: flex;
      //justify-content: left;
      color: rgb(255, 255, 255);

      background-color: rgb(79, 103, 130);
      font-family: 'EBGaramond-VariableFont_wght' !important;
      font-size: 28px !important;
      border-radius: unset;
      padding-left: 4rem;
      padding-right: 4rem;
      bottom: 25%;
      margin-left: unset;
    }
  }
</style>