<template>
  <b-navbar>
    <div class="quick-search">
      <!-- <quick-search/> -->
    </div>
    <div class="middle-item">
      <!-- <b-navbar-brand href="/">
        <h1>Open Zone Map</h1>
      </b-navbar-brand> -->
    </div>
    <div class="end-item">
      <b-navbar-brand v-on:click = "showMenu">
        <b-icon-justify class="rounded-circle border p-2"/>
      </b-navbar-brand>
    </div>
    <Menu></Menu>  
  </b-navbar>
</template>

<script>
import Menu from '../components/Menu.vue'
import {BIconJustify} from 'bootstrap-vue';
export default {
  components: { 
    Menu,
    BIconJustify
  },
  data() {
    return {
    }
  },
  methods: {
    showMenu() {
      var element = document.querySelector("#menu");
      element.style.visibility = "visible";
      var op = 0.1;  // initial opacity
      element.style.display = 'block';
      var timer = setInterval(
        function () {
          if (op >= 1){
            clearInterval(timer);
          }
          element.style.opacity = op;
          element.style.filter = 'alpha(opacity=' + op * 100 + ")";
          op += op * 0.1;

        }, 
        10
      );
    },
  }
}
</script>

<style scoped lang="scss">
  .navbar{
    height: 86px;
    margin-bottom: 2.5rem !important;
    .navbar-brand {
      cursor: pointer;
      svg {
        color: rgb(79, 103, 130);
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .border {
      border: 2px solid !important;
      border-color: rgb(79, 103, 130) !important;
    }
    .navbar-brand:hover {
      svg {
        color: rgb(167, 135, 109);
      }
      .border {
        border-color: rgb(167, 135, 109) !important;
      }
    }
  }
  .navbar-expand {
    justify-content: flex-end !important;
  }

/** Slide in right animation  */
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-16 16:30:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/** Slide out right */
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-16 16:32:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
</style>